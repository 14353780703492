import { useState } from 'react';
import { Box, IconButton, TextField } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useSWRConfig } from 'swr';
import post from '../../../libs/post';
import showMessage from '../../../utils/showMessage';

const NewJudge: React.FC = () => {
	const [name, setName] = useState('');
	const { mutate } = useSWRConfig();

	return (
		<Box sx={{ display: 'flex' }}>
			<Box sx={{ flexGrow: 1 }}>
				<TextField
					label="Nový rozhodčí"
					fullWidth
					size="small"
					value={name}
					onChange={(event) => setName(event.target.value)}
				/>
			</Box>

			<Box>
				<IconButton
					onClick={async () => {
						try {
							const response = await post(`/api/settings/judges`, { name });
							if (response.status === 200) {
								setName('');
								mutate('/api/settings/judges');

								showMessage({
									msg: `Rozhodčí ${name} přidán.`,
								});
							}
						} catch (e) {
							showMessage({
								msg: `Chyba při přidávání rozhodčího.`,
								severity: 'error',
								duration: 60000,
							});
						}
					}}
				>
					<AddCircleOutlineIcon />
				</IconButton>
			</Box>
		</Box>
	);
};

export default NewJudge;
