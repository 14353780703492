import { FC } from 'react';

import { TableCell, TableRow, styled } from '@mui/material';

import { convertPohlavi, convertSrst, convertTrida } from '../../utils';
import { Dog } from '../../types';

const TableRowRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.background.default,
	},

	'&:hover': {
		cursor: 'pointer',
		backgroundColor: '#e0e0e0',
	},
}));

const TableCellKatalogoveCislo = styled(TableCell)(({ theme }) => ({
	width: 100,
}));

const TableCellThin = styled(TableCell)(({ theme }) => ({
	width: 50,
}));

const TableCellMajitel = styled(TableCell)(({ theme }) => ({
	width: 320,
}));

interface IShowTableRowProps {
	data: Dog;
	onClick: () => void;
	isBonitace: boolean;
}

const ShowTableRow: FC<IShowTableRowProps> = ({ data, onClick, isBonitace }) => {
	return (
		<TableRowRow onClick={onClick}>
			<TableCellKatalogoveCislo>{data.cisloVKatalogu}</TableCellKatalogoveCislo>

			<TableCell>{data.jmeno}</TableCell>

			<TableCell>{data.cisloZapisu}</TableCell>

			<TableCellThin>{convertPohlavi(data.pohlavi)}</TableCellThin>

			{!isBonitace && (
				<>
					<TableCellThin>{convertSrst(data.srst)}</TableCellThin>

					<TableCellThin>{convertTrida(data.trida)}</TableCellThin>
				</>
			)}

			<TableCellMajitel>{data.majitelJmeno}</TableCellMajitel>
		</TableRowRow>
	);
};

export default ShowTableRow;
