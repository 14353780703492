import { FC, useRef } from 'react';

import { GetApp as GetAppIcon, Save as SaveIcon } from '@mui/icons-material';
import { Button } from '@mui/material';

import upload from '../../libs/upload';
import showMessage from '../../utils/showMessage';

interface IProps {
	title: string;
	currentUrl: string;
	defaultUrl: string;
	uploadUrl: string;
}

const TemplateButtonsPanel: FC<IProps> = ({ title, currentUrl, defaultUrl, uploadUrl }) => {
	const hiddenFileInput = useRef<HTMLInputElement>(null);

	const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const fileUploaded = event.target.files?.[0];

		try {
			const formData = new FormData();
			formData.append('file', fileUploaded!);

			const response = await upload(uploadUrl, formData);

			if (response.status === 201) {
				showMessage({
					msg: `Šablona byla uložena.`,
				});
			}
		} catch (e) {
			console.log(e);
			showMessage({
				msg: `Chyba při ukládání.`,
				severity: 'error',
				duration: 60000,
			});
		}
	};

	return (
		<>
			<h3>{title}</h3>
			<Button
				color="primary"
				startIcon={<GetAppIcon />}
				variant="outlined"
				download
				href={currentUrl}
			>
				Stáhnout aktuální šablonu
			</Button>{' '}
			<Button
				color="primary"
				startIcon={<GetAppIcon />}
				variant="outlined"
				download
				href={defaultUrl}
			>
				Stáhnout výchozí šablonu
			</Button>{' '}
			<input
				type="file"
				name="file"
				ref={hiddenFileInput}
				onChange={handleFileChange}
				style={{ display: 'none' }}
			/>
			<Button
				color="secondary"
				startIcon={<SaveIcon />}
				variant="outlined"
				onClick={() => {
					hiddenFileInput.current?.click();
				}}
			>
				Nahrát novou šablonu
			</Button>
		</>
	);
};

export default TemplateButtonsPanel;
