import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { mutate } from 'swr';

import {
	ExitToApp as ExitToAppIcon,
	Menu as MenuIcon,
	Person as PersonIcon,
} from '@mui/icons-material';
import {
	AppBar as MuiAppBar,
	AppBarProps as MuiAppBarProps,
	IconButton,
	Toolbar,
	Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { useStatus } from './StatusContext';
import { drawerWidth } from './Menu';
import { getMenuDescription, menu } from '../menu';
import post from '../libs/post';

interface AppBarProps extends MuiAppBarProps {
	open?: boolean;
}

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

interface INavbarProps {
	isMenuOpen: boolean;
	onOpenMenu: () => void;
}

const Navbar: FC<INavbarProps> = ({ isMenuOpen, onOpenMenu }) => {
	const location = useLocation();
	const navigate = useNavigate();
	const { isLoggedIn } = useStatus();

	const logout = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.preventDefault();

		const response = await post('/api/users/logout');

		if (response.status === 200) {
			await mutate('/api/status');
			navigate('/login');
		}
	};

	const openUserProfile = () => {
		navigate(menu.profile.path);
	};

	return (
		<AppBar position="fixed" open={isMenuOpen}>
			<Toolbar
				sx={{
					paddingRight: 24,
				}}
			>
				<IconButton
					edge="start"
					color="inherit"
					title="Zobrazit menu"
					onClick={onOpenMenu}
					sx={{
						marginRight: '36px',
						...(isMenuOpen && { display: 'none' }),
					}}
					size="large"
				>
					<MenuIcon />
				</IconButton>

				<Typography
					component="h2"
					variant="h6"
					color="inherit"
					noWrap
					sx={{
						flexGrow: 1,
					}}
				>
					{getMenuDescription(location.pathname)}
				</Typography>

				{isLoggedIn && (
					<>
						<IconButton
							onClick={openUserProfile}
							color="inherit"
							title={menu.profile.menu}
							size="large"
						>
							<PersonIcon />
						</IconButton>
						<IconButton
							color="inherit"
							onClick={logout}
							title="Odhlásit se"
							size="large"
						>
							<ExitToAppIcon />
						</IconButton>
					</>
				)}
			</Toolbar>
		</AppBar>
	);
};

export default Navbar;
