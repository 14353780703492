import { FC } from 'react';
import { Formik } from 'formik';
import useSWR, { useSWRConfig } from 'swr';

import { Save as SaveIcon } from '@mui/icons-material';
import { Box, Button, TextField, Typography } from '@mui/material';

import put from '../../libs/put';
import { Show } from '../../types';
import showMessage from '../../utils/showMessage';
import GreenSwitch from '../../components/GreenSwitch';
import { useStatus } from '../../components/StatusContext';

const ShowSettings: FC = () => {
	const { isBonitace } = useStatus();
	const { mutate } = useSWRConfig();
	const { data } = useSWR<Show>('/api/settings/show');

	if (!data) {
		return null;
	}

	return (
		<Formik
			initialValues={data}
			enableReinitialize
			onSubmit={async (values) => {
				try {
					const response = await put(`/api/settings/show`, values);
					if (response.status === 204) {
						mutate('/api/settings/show');
						showMessage({
							msg: `Data uložena.`,
						});
					}
				} catch (e) {
					console.log(e);
					showMessage({
						msg: `Chyba při ukládání.`,
						severity: 'error',
						duration: 60000,
					});
				}
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => {
				return (
					<form onSubmit={handleSubmit}>
						<Box p={3}>
							<Typography variant="h6">
								Detaily {isBonitace ? 'bonitace' : 'výstavy'}
							</Typography>
							<Box>
								<TextField
									id="nazev"
									label="Název"
									value={values.nazev}
									onChange={handleChange}
									margin="normal"
									variant="standard"
									fullWidth
								/>
							</Box>
							<Box>
								<TextField
									id="misto"
									label="Místo konání"
									value={values.misto}
									onChange={handleChange}
									margin="normal"
									variant="standard"
									fullWidth
								/>
							</Box>
							<Box>
								<TextField
									id="mistoV"
									label="Místo konání začínající na 'v' nebo 've'. Např. 'v Troubelicích'"
									value={values.mistoV}
									onChange={handleChange}
									margin="normal"
									variant="standard"
									fullWidth
								/>
							</Box>
							<Box>
								<TextField
									id="datum"
									label="Datum konání"
									value={values.datum}
									onChange={handleChange}
									margin="normal"
									variant="standard"
								/>
							</Box>
						</Box>

						<Box p={3}>
							<Typography variant="h6">Zapnout</Typography>
							<Box>
								<GreenSwitch
									id="available"
									checked={values.available}
									onChange={handleChange}
								/>
							</Box>
							<Box>
								{values.available ? (
									<span>
										{isBonitace ? 'Bonitace' : 'Výstava'} se zobrazuje na hlavní
										stránce{' '}
										<a
											href="https://vystava-psu.eu"
											target="_blank"
											rel="noopener noreferrer"
										>
											vystava-psu.eu
										</a>{' '}
										a lidé se na ni mohou přihlašovat.
									</span>
								) : (
									<span>
										{isBonitace ? 'Bonitace' : 'Výstava'} není dostupná pro
										přihlašování.
									</span>
								)}
							</Box>
						</Box>

						<Box p={3} display="flex" justifyContent="flex-end">
							<Button
								type="submit"
								disabled={isSubmitting}
								color="primary"
								startIcon={<SaveIcon />}
								variant="contained"
							>
								Uložit
							</Button>
						</Box>
						{/* </Container> */}
					</form>
				);
			}}
		</Formik>
	);
};

export default ShowSettings;
