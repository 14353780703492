import { useState } from 'react';
import { useSWRConfig } from 'swr';
import { Box, IconButton, ListItemText, TextField } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';

import { IJudge } from '../../../types';
import remove from '../../../libs/remove';
import showMessage from '../../../utils/showMessage';
import put from '../../../libs/put';

interface EditJudgeProps {
	judge: IJudge;
}

const EditJudge: React.FC<EditJudgeProps> = ({ judge }) => {
	const [editMode, setEditMode] = useState(false);
	const [name, setName] = useState(judge.name);
	const { mutate } = useSWRConfig();

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
				borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
				py: '4px',
				my: '4px',
			}}
		>
			<Box sx={{ flexGrow: 1 }}>
				{editMode ? (
					<TextField
						label="Úprava rozhodčího"
						fullWidth
						size="small"
						value={name}
						onChange={(event) => setName(event.target.value)}
					/>
				) : (
					<Box sx={{ pl: '8px' }}>
						<ListItemText primary={judge.name} />
					</Box>
				)}
			</Box>

			<Box>
				{editMode ? (
					<IconButton
						onClick={async () => {
							try {
								const response = await put(`/api/settings/judges/${judge.id}`, {
									name,
								});
								if (response.status === 204) {
									mutate('/api/settings/judges');
									setEditMode(false);
									showMessage({
										msg: `Rozhodčí ${name} upraven.`,
									});
								}
							} catch (e) {
								showMessage({
									msg: `Chyba při úpravě rozhodčího.`,
									severity: 'error',
									duration: 60000,
								});
							}
						}}
					>
						<SaveIcon />
					</IconButton>
				) : (
					<IconButton onClick={() => setEditMode(true)}>
						<EditIcon />
					</IconButton>
				)}

				<IconButton
					onClick={async () => {
						try {
							const response = await remove(`/api/settings/judges/${judge.id}`);
							if (response.status === 204) {
								mutate('/api/settings/judges');

								showMessage({
									msg: `Rozhodčí ${judge.name} smazán.`,
								});
							}
						} catch (e) {
							showMessage({
								msg: `Chyba při mazání rozhodčího.`,
								severity: 'error',
								duration: 60000,
							});
						}
					}}
				>
					<DeleteOutlineIcon />
				</IconButton>
			</Box>
		</Box>
	);
};

export default EditJudge;
