import { useState, FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import { Drawer } from '@mui/material';

import { Registration } from '../../types';
import RegistrationsTable from './RegistrationsTable';
import RegisterDogForm from './RegisterDogForm';
import Loading from '../../components/Loading';
import { menu } from '../../menu';
import EmptyData from '../../components/EmptyData';

const RegistrationsPage: FC = () => {
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		document.title = 'Přihlášky - Výstava psů';
	}, []);

	const [selectedRow, setSelectedRow] = useState<Registration | null>(null);

	const { data } = useSWR<Registration[]>('/api/registrations');

	useEffect(() => {
		if (location.pathname !== menu.registrations.path) {
			const registrationId = parseInt(location.pathname.split('/').pop() ?? '', 10);
			if (!isNaN(registrationId) && data) {
				const registrationRow = data.find(
					(registration) => registration.id === registrationId,
				);
				if (registrationRow) {
					setSelectedRow(registrationRow);
				}
			}
		}
	}, [location, data]);

	const handleCloseBlade = () => {
		navigate(menu.registrations.path);
		setSelectedRow(null);
	};

	if (!data) {
		return <Loading />;
	}

	if (data.length === 0) {
		return <EmptyData>Nemáte žádné přihlášky</EmptyData>;
	}

	return (
		<>
			<RegistrationsTable
				data={data}
				onEdit={(row) => {
					navigate(`${menu.registrations.path}/${row.id}`);
					setSelectedRow(row);
				}}
			/>

			<Drawer open={!!selectedRow} anchor="right" onClose={handleCloseBlade}>
				<RegisterDogForm data={selectedRow!} onClose={handleCloseBlade} />
			</Drawer>
		</>
	);
};

export default RegistrationsPage;
