import { FC } from 'react';
import useSWR from 'swr';

import {
	styled,
	Table,
	TableBody,
	TableCell,
	TableCellProps,
	TableHead,
	TableRow,
} from '@mui/material';

import { IStatistics } from '../../types';
import { convertTrida } from '../../utils';
import { Pohlavi, Srst } from '../../enums';

function getCountFor(
	statistics: IStatistics['statistics'],
	trida: number,
	pohlavi: string,
	srst: string,
) {
	for (const stat of statistics) {
		if (trida === stat.trida && pohlavi === stat.pohlavi && srst === stat.srst) {
			return stat.count;
		}
	}
	return 0;
}

const HeaderCell = styled((props) => <TableCell align="center" {...props} />)<TableCellProps>(
	({ theme }) => ({
		backgroundColor: theme.palette.grey[200],
		color: theme.palette.getContrastText(theme.palette.grey[200]),
	}),
);

const UnapprovedCell = styled(TableCell)<TableCellProps>(({ theme }) => ({
	backgroundColor: theme.palette.warning.light,
	color: theme.palette.warning.contrastText,
	borderTop: `1px solid ${theme.palette.grey[300]}`,
	borderBottom: 0,
}));

const StatisticsPanel: FC = () => {
	const { data } = useSWR<IStatistics>('/api/dogs/statistics');

	if (!data) {
		return null;
	}

	let fkCelkem = 0;
	let pkCelkem = 0;
	let fdCelkem = 0;
	let pdCelkem = 0;

	return (
		<Table>
			<TableHead>
				<TableRow>
					<HeaderCell>Třída</HeaderCell>
					<HeaderCell
						colSpan={4}
						sx={{ borderLeft: (theme) => `1px solid ${theme.palette.grey[300]}` }}
					>
						Srst
					</HeaderCell>
					<HeaderCell
						colSpan={3}
						sx={{ borderLeft: (theme) => `1px solid ${theme.palette.grey[300]}` }}
					>
						Celkem
					</HeaderCell>
				</TableRow>
				<TableRow>
					<HeaderCell></HeaderCell>
					<HeaderCell
						colSpan={2}
						sx={{ borderLeft: (theme) => `1px solid ${theme.palette.grey[300]}` }}
					>
						krátká
					</HeaderCell>
					<HeaderCell colSpan={2}>dlouhá</HeaderCell>
					<HeaderCell
						sx={{ borderLeft: (theme) => `1px solid ${theme.palette.grey[300]}` }}
					></HeaderCell>
					<HeaderCell></HeaderCell>
					<HeaderCell></HeaderCell>
				</TableRow>
				<TableRow>
					<HeaderCell></HeaderCell>
					<HeaderCell
						sx={{ borderLeft: (theme) => `1px solid ${theme.palette.grey[300]}` }}
					>
						feny
					</HeaderCell>
					<HeaderCell>psi</HeaderCell>
					<HeaderCell>feny</HeaderCell>
					<HeaderCell>psi</HeaderCell>
					<HeaderCell
						sx={{ borderLeft: (theme) => `1px solid ${theme.palette.grey[300]}` }}
					>
						feny
					</HeaderCell>
					<HeaderCell>psi</HeaderCell>
					<HeaderCell>dohromady</HeaderCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{data.tridaOrder.sort().map((tridaId) => {
					const fk = getCountFor(data.statistics, tridaId, Pohlavi.FENA, Srst.KRATKA);
					const pk = getCountFor(data.statistics, tridaId, Pohlavi.PES, Srst.KRATKA);
					const fd = getCountFor(data.statistics, tridaId, Pohlavi.FENA, Srst.DLOUHA);
					const pd = getCountFor(data.statistics, tridaId, Pohlavi.PES, Srst.DLOUHA);

					fkCelkem += fk;
					pkCelkem += pk;
					fdCelkem += fd;
					pdCelkem += pd;

					return (
						<TableRow key={tridaId}>
							<TableCell>{convertTrida(tridaId)}</TableCell>
							<TableCell
								align="center"
								sx={{
									borderLeft: (theme) => `1px solid ${theme.palette.grey[300]}`,
								}}
							>
								{fk}
							</TableCell>
							<TableCell align="center">{pk}</TableCell>
							<TableCell align="center">{fd}</TableCell>
							<TableCell align="center">{pd}</TableCell>
							<TableCell
								align="center"
								sx={{
									borderLeft: (theme) => `1px solid ${theme.palette.grey[300]}`,
								}}
							>
								{fk + fd}
							</TableCell>
							<TableCell align="center">{pk + pd}</TableCell>
							<TableCell align="center">{fk + fd + pk + pd}</TableCell>
						</TableRow>
					);
				})}

				<TableRow>
					<TableCell sx={{ borderBottom: 0 }}>
						<strong>Celkem</strong>
					</TableCell>
					<TableCell
						align="center"
						sx={{
							borderLeft: (theme) => `1px solid ${theme.palette.grey[300]}`,
							borderBottom: 0,
						}}
					>
						{fkCelkem}
					</TableCell>
					<TableCell align="center" sx={{ borderBottom: 0 }}>
						{pkCelkem}
					</TableCell>
					<TableCell align="center" sx={{ borderBottom: 0 }}>
						{fdCelkem}
					</TableCell>
					<TableCell align="center" sx={{ borderBottom: 0 }}>
						{pdCelkem}
					</TableCell>
					<TableCell
						align="center"
						sx={{
							borderLeft: (theme) => `1px solid ${theme.palette.grey[300]}`,
							borderBottom: 0,
						}}
					>
						{fkCelkem + fdCelkem}
					</TableCell>
					<TableCell align="center" sx={{ borderBottom: 0 }}>
						{pkCelkem + pdCelkem}
					</TableCell>
					<TableCell align="center" sx={{ borderBottom: 0 }}>
						<strong>{fkCelkem + fdCelkem + pkCelkem + pdCelkem}</strong>
					</TableCell>
				</TableRow>

				{data.unapprovedRegistrationsCount > 0 && (
					<TableRow>
						<UnapprovedCell colSpan={7}>Neschválených přihlášek</UnapprovedCell>
						<UnapprovedCell align="center">
							{data.unapprovedRegistrationsCount}
						</UnapprovedCell>
					</TableRow>
				)}
			</TableBody>
		</Table>
	);
};

export default StatisticsPanel;
