import { useState, FC, useEffect } from 'react';

import { Box, Button, Container, Drawer, Typography } from '@mui/material';

import NewRegistrationForm from './NewRegistrationForm';

const RegistrationsPage: FC = () => {
	useEffect(() => {
		document.title = 'Nová přihláška - Výstava psů';
	}, []);

	const [openForm, setOpenForm] = useState(false);

	return (
		<>
			<Container maxWidth="md">
				<Box
					sx={{
						my: 3,
						px: 5,
						py: 3,
						borderRadius: '10px',
						border: 1,
						borderColor: 'divider',
						backgroundColor: 'background.paper',
					}}
				>
					<Typography>
						Pokud Vám dorazila přihláška jinou formou (např. emailem, poštou) než přes
						on-line přihlašovací formulář.
					</Typography>

					<Typography>
						Tato přihláška bude hned i schválena, takže ji už nemusíte schvalovat.
					</Typography>

					<Box m={1} mt={3}>
						<Button
							variant="contained"
							color="primary"
							onClick={() => setOpenForm(true)}
						>
							Vyplnit přihlášku
						</Button>
					</Box>
				</Box>
			</Container>

			<Drawer
				open={openForm}
				anchor="right"
				onClose={() => {
					setOpenForm(false);
				}}
			>
				<NewRegistrationForm
					onClose={() => {
						setOpenForm(false);
					}}
				/>
			</Drawer>
		</>
	);
};

export default RegistrationsPage;
