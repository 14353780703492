import { useEffect, useState, FC } from 'react';

import { Save as SaveIcon } from '@mui/icons-material';
import { Button, MenuItem, TableCell, TableRow, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

import { convertPohlavi, convertSrst, convertTrida } from '../../utils';
import { Dog } from '../../types';
import put from '../../libs/put';

const hodnoceni = [
	{ value: '1', label: 'Výborný' },
	{ value: '2', label: 'Velmi dobrý' },
	{ value: '3', label: 'Dobrý' },
	// { value: '4', label: 'Dostatečný' },
	// { value: '5', label: 'Nedostatečný' },
	{ value: '6', label: 'Velmi nadějný' },
	{ value: '7', label: 'Nadějný' },
	{ value: '8', label: 'Nenastoupil' },
	{ value: '9', label: 'Odstoupil' },
];

const CustomTableCell = styled(TableCell)(({ theme }) => ({
	head: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	body: {
		fontSize: 14,
		padding: '4px 10px',
	},
}));

const Row: FC<{ row: Dog; isBonitace: boolean }> = (props) => {
	const { row, isBonitace } = props;

	const [data, setData] = useState<Dog>(row);
	const [changed, setChanged] = useState(false);

	useEffect(() => {
		setData(row);
	}, [row]);

	const handleSubmit = async (event: React.MouseEvent) => {
		event.preventDefault();

		await put(`/api/dogs/${data.id}`, {
			hodnoceni: data.hodnoceni,
			poradi: data.poradi,
		});

		setChanged(false);
	};

	const handleChangeHodnoceni = (event: React.ChangeEvent<HTMLInputElement>) => {
		setData({
			...data,
			hodnoceni: parseInt(event.target.value, 10),
		});
		setChanged(true);
	};

	const handleChangePoradi = (event: React.ChangeEvent<HTMLInputElement>) => {
		setData({
			...data,
			poradi: parseInt(event.target.value, 10),
		});
		setChanged(true);
	};

	return (
		<TableRow
			sx={{
				'&:nth-of-type(odd)': {
					backgroundColor: (theme) => theme.palette.background.default,
				},
			}}
		>
			<CustomTableCell component="th" scope="row" sx={{ width: 120 }}>
				{data.cisloVKatalogu}
			</CustomTableCell>

			<CustomTableCell>{data.jmeno}</CustomTableCell>

			<CustomTableCell>{data.cisloZapisu}</CustomTableCell>

			<CustomTableCell
				sx={{
					width: 120,
				}}
			>
				{convertPohlavi(data.pohlavi)}
			</CustomTableCell>

			{!isBonitace && (
				<>
					<CustomTableCell sx={{ width: 120 }}>{convertSrst(data.srst)}</CustomTableCell>

					<CustomTableCell sx={{ width: 120 }}>
						{convertTrida(data.trida)}
					</CustomTableCell>
				</>
			)}

			<CustomTableCell sx={{ width: 120 }}>
				<TextField
					select
					value={data.hodnoceni ? data.hodnoceni : ''}
					onChange={handleChangeHodnoceni}
					SelectProps={{
						MenuProps: {
							sx: { width: 200 },
						},
					}}
					margin="normal"
					variant="standard"
					sx={(theme) => ({
						marginLeft: theme.spacing(1),
						marginRight: theme.spacing(1),
						width: 150,
					})}
				>
					{hodnoceni.map((option) => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</TextField>
			</CustomTableCell>

			<CustomTableCell>
				<TextField
					value={data.poradi ? data.poradi : ''}
					onChange={handleChangePoradi}
					margin="normal"
					variant="standard"
					sx={(theme) => ({
						marginLeft: theme.spacing(1),
						marginRight: theme.spacing(1),
						width: 150,
					})}
				/>
			</CustomTableCell>

			<CustomTableCell align="right" sx={{ width: 140 }}>
				{changed && (
					<Button
						variant="contained"
						onClick={handleSubmit}
						sx={{ margin: (theme) => theme.spacing(1) }}
					>
						<SaveIcon sx={{ fontSize: 20 }} /> Uložit
					</Button>
				)}
			</CustomTableCell>
		</TableRow>
	);
};

export default Row;
