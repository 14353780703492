import { FC, useEffect } from 'react';
import useSWR from 'swr';

import { Box } from '@mui/system';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	styled,
} from '@mui/material';

import ResultsTableRow from './ResultsTableRow';
import { Dog } from '../../types';
import Loading from '../../components/Loading';
import { useStatus } from '../../components/StatusContext';
import EmptyData from '../../components/EmptyData';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
	overflowX: 'visible',
	paddingBottom: theme.spacing(3),
	backgroundColor: theme.palette.background.default,
}));

const StyledTable = styled(Table)(({ theme }) => ({
	maxWidth: 1300,
	margin: '0 auto',
	padding: theme.spacing(0, 2, 2),
	backgroundColor: theme.palette.background.paper,
	borderRight: `1px solid`,
	borderLeft: `1px solid`,
	borderBottom: `1px solid`,
	borderColor: theme.palette.divider,
	borderBottomLeftRadius: 10,
	borderBottomRightRadius: 10,
}));

const TableCellTableHead = styled(TableCell)(({ theme }) => ({
	borderBottom: `4px solid ${theme.palette.primary.main}`,
	backgroundColor: theme.palette.background.paper,
	fontWeight: 'bold',
	top: 104,
	height: 58,
}));

const ResultsPage: FC = () => {
	const { isBonitace } = useStatus();

	useEffect(() => {
		document.title = 'Výsledky - Výstava psů';
	}, []);

	const { data } = useSWR<Dog[]>('/api/dogs/results-editing');

	if (!data) {
		return <Loading />;
	}

	if (data.length === 0) {
		return <EmptyData>Nemáte žádné schválené přihlášky</EmptyData>;
	}

	return (
		<StyledTableContainer>
			<Box
				sx={{
					backgroundColor: 'background.default',
					height: 24,
					margin: 'auto',
					width: 1300,
					position: 'sticky',
					top: 64,
					zIndex: 2,
				}}
			/>
			<Box
				sx={{
					border: 1,
					borderColor: 'divider',
					backgroundColor: 'background.paper',
					height: 16,
					margin: 'auto',
					width: 1300,
					borderTopLeftRadius: 10,
					borderTopRightRadius: 10,
					borderBottom: 0,
					position: 'sticky',
					top: 88,
					zIndex: 2,
					boxShadow: (theme) => `0px -8px 0px 8px ${theme.palette.background.default}`,
				}}
			/>

			<StyledTable stickyHeader>
				<TableHead>
					<TableRow>
						<TableCellTableHead>Kat. č.</TableCellTableHead>
						<TableCellTableHead>Jméno</TableCellTableHead>
						<TableCellTableHead>Číslo zápisu</TableCellTableHead>
						<TableCellTableHead>Pohlaví</TableCellTableHead>
						{!isBonitace && (
							<>
								<TableCellTableHead>Srst</TableCellTableHead>
								<TableCellTableHead>Třída</TableCellTableHead>
							</>
						)}
						<TableCellTableHead>Hodnocení</TableCellTableHead>
						<TableCellTableHead>Pořadí</TableCellTableHead>
						<TableCellTableHead style={{ width: '140px' }} />
					</TableRow>
				</TableHead>

				<TableBody>
					{data.map((row) => (
						<ResultsTableRow key={row.id} row={row} isBonitace={isBonitace} />
					))}
				</TableBody>
			</StyledTable>
		</StyledTableContainer>
	);
};

export default ResultsPage;
