import { FC, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import { Formik } from 'formik';

import { LockOutlined as LockOutlinedIcon } from '@mui/icons-material';
import {
	AppBar,
	Avatar,
	Box,
	Button,
	CssBaseline,
	FormControl,
	Input,
	InputLabel,
	Toolbar,
	Typography,
	Alert,
	Container,
} from '@mui/material';

import { useStatus } from '../components/StatusContext';
import post from '../libs/post';
import { useEffect } from 'react';

const LoginPage: FC = () => {
	useEffect(() => {
		document.title = 'Výstava psů - Přihlášení';
	}, []);

	const [errorMessage, setErrorMessage] = useState(null);
	const navigate = useNavigate();
	const { isLoggedIn } = useStatus();
	const { mutate } = useSWRConfig();

	useEffect(() => {
		if (isLoggedIn) {
			navigate('/');
		}
	}, [navigate, isLoggedIn]);

	if (isLoggedIn) {
		return null;
	}

	return (
		<>
			<AppBar position="fixed">
				<Toolbar>
					<Typography
						variant="h6"
						component={Link}
						to="/"
						sx={{
							color: (theme) => theme.palette.common.white,
							textDecoration: 'none',
						}}
					>
						Výstava psů
					</Typography>
				</Toolbar>
			</AppBar>
			<Container maxWidth="sm" sx={{ mt: 5 }}>
				<CssBaseline />
				<Box
					sx={{
						mx: 'auto',
						mt: 8,
						p: 2,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						borderRadius: 2,
						border: 1,
						borderColor: 'divider',
					}}
				>
					<Avatar
						sx={{
							mt: 4,
							mb: 2,
							backgroundColor: 'secondary.main',
						}}
					>
						<LockOutlinedIcon />
					</Avatar>

					<Typography component="h1" variant="h5">
						Přihlášení
					</Typography>

					<Formik
						initialValues={{ email: '', password: '' }}
						onSubmit={async ({ email, password }) => {
							setErrorMessage(null);
							const response = await post('/api/users/login', {
								email,
								password,
							});

							if (response.status === 200) {
								mutate('/api/status');
								navigate('/');
							} else {
								console.log({ response });
								setErrorMessage(response.data.message);
							}
						}}
					>
						{({
							values,
							touched,
							errors,
							dirty,
							isSubmitting,
							handleChange,
							handleBlur,
							handleSubmit,
							handleReset,
						}) => {
							return (
								<form onSubmit={handleSubmit}>
									<FormControl
										margin="normal"
										required
										fullWidth
										sx={{
											mt: 6,
											mb: 1,
										}}
									>
										<InputLabel htmlFor="email">E-mail</InputLabel>
										<Input
											id="email"
											name="email"
											autoFocus
											value={values.email}
											onChange={handleChange}
											onBlur={handleBlur}
											autoComplete="email"
										/>
									</FormControl>

									<FormControl margin="normal" required fullWidth>
										<InputLabel htmlFor="password">Heslo</InputLabel>
										<Input
											name="password"
											type="password"
											id="password"
											value={values.password}
											onChange={handleChange}
											onBlur={handleBlur}
											autoComplete="password"
										/>
									</FormControl>

									{/*
									 <FormControlLabel
										control={<Checkbox value="remember" color="primary" />}
										label="Zapamatovat si mě"
									/>
									*/}

									{errorMessage && <Alert severity="error">{errorMessage}</Alert>}

									<Box
										sx={{
											mt: 7,
											mb: 3,
											display: 'flex',
											justifyContent: 'flex-end',
										}}
									>
										<Button
											type="submit"
											variant="contained"
											color="primary"
											disabled={isSubmitting}
										>
											Přihlásit se
										</Button>
									</Box>
								</form>
							);
						}}
					</Formik>
				</Box>
				<Box
					sx={{
						pt: 2,
						pr: 1,
						display: 'flex',
						justifyContent: 'flex-end',
					}}
				>
					<Link to="/zapomenute-heslo">Zapomenuté heslo</Link>
				</Box>
			</Container>
		</>
	);
};

export default LoginPage;
