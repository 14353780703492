import { createRoot } from 'react-dom/client';

import { AlertColor } from '@mui/material';

import Message from '../components/Message';

type TShowMessage = {
	msg: string;
	duration?: number;
	severity?: AlertColor;
};

const showMessage = ({ msg, duration = 10000, severity = 'success' }: TShowMessage) => {
	const div = document.createElement('div');
	document.body.appendChild(div);

	createRoot(div).render(<Message msg={msg} duration={duration} severity={severity} />);

	setTimeout(() => {
		document.body.removeChild(div);
	}, duration + 200);
};

export default showMessage;
