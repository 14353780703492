import { useEffect, useMemo, useState, FC } from 'react';
import { useDebounce } from '@react-hook/debounce';

import { Box } from '@mui/system';
import { Table, TableBody, TableContainer, styled } from '@mui/material';

import { Dog } from '../../types';
import ShowTableRow from './ShowTableRow';
import TableHead from './ShowTableHead';
import { stableSort, getComparator, Order } from '../../utils/tableSort';
import { useFilter } from './useFilter';
import { useStatus } from '../../components/StatusContext';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
	overflowX: 'visible',
	paddingBottom: theme.spacing(3),
	backgroundColor: theme.palette.background.default,
}));

const StyledTable = styled(Table)(({ theme }) => ({
	maxWidth: 1300,
	margin: '0 auto',
	padding: theme.spacing(0, 2, 2),
	backgroundColor: theme.palette.background.paper,
	borderRight: `1px solid`,
	borderLeft: `1px solid`,
	borderBottom: `1px solid`,
	borderColor: theme.palette.divider,
	borderBottomLeftRadius: 10,
	borderBottomRightRadius: 10,
}));

interface IShowTableProps {
	data: Dog[];
	onEdit: (row: Dog) => void;
}

const ShowTable: FC<IShowTableProps> = ({ data, onEdit }) => {
	const { isBonitace } = useStatus();

	const [order, setOrder] = useState<Order>('desc');
	const [orderBy, setOrderBy] = useState<keyof Dog>('cisloVKatalogu');

	const { showRow, ...filterFields } = useFilter();

	const [filteredData, setFilteredData] = useDebounce(
		stableSort(data, getComparator(order, orderBy)),
		300,
	);

	useEffect(() => {
		setFilteredData(stableSort(data, getComparator(order, orderBy)).filter(showRow));
	}, [data, order, orderBy, setFilteredData, showRow]);

	const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Dog) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	return (
		<StyledTableContainer>
			<Box
				sx={{
					backgroundColor: 'background.default',
					height: 24,
					margin: 'auto',
					width: 1300,
					position: 'sticky',
					top: 64,
				}}
			/>
			<Box
				sx={{
					border: 1,
					borderColor: 'divider',
					backgroundColor: 'background.paper',
					height: 16,
					margin: 'auto',
					width: 1300,
					borderTopLeftRadius: 10,
					borderTopRightRadius: 10,
					borderBottom: 0,
					position: 'sticky',
					top: 88,
					boxShadow: (theme) => `0px -8px 0px 8px ${theme.palette.background.default}`,
				}}
			/>

			<StyledTable stickyHeader>
				<TableHead
					order={order}
					orderBy={orderBy}
					onRequestSort={handleRequestSort}
					filterFields={filterFields}
					isBonitace={isBonitace}
				/>

				{useMemo(() => {
					return (
						<TableBody>
							{filteredData.map((row) => {
								return (
									<ShowTableRow
										key={row.id}
										data={row}
										onClick={() => onEdit(row)}
										isBonitace={isBonitace}
									/>
								);
							})}
						</TableBody>
					);
				}, [filteredData, onEdit, isBonitace])}
			</StyledTable>
		</StyledTableContainer>
	);
};

export default ShowTable;
