import AddIcon from '@mui/icons-material/AddCircleOutlineRounded';
import BarChartIcon from '@mui/icons-material/BarChart';
import EmojiEventsIcon from '@mui/icons-material/EmojiEventsOutlined';
import PetsIcon from '@mui/icons-material/Pets';
import PrintIcon from '@mui/icons-material/Print';
import SettingsIcon from '@mui/icons-material/Settings';

import { SvgIcon } from '@mui/material';

export const getMenuDescription = (path: string) => {
	let retVal = '';
	Object.values(menu).forEach((menuItem) => {
		if (path.includes(menuItem.path)) {
			retVal = menuItem.description;
		}
	});

	return retVal;
};

export interface IMenuItem {
	path: string;
	menu: string;
	description: string;
	MenuIcon: typeof SvgIcon;
	skipMenu: boolean;
	hideBonitace: boolean;
	children?: Array<{ path: string; title: string }>;
}

type MenuName =
	| 'home'
	| 'profile'
	| 'newRegistration'
	| 'registrations'
	| 'show'
	| 'results'
	| 'print'
	| 'settings';

export const menu: { [name in MenuName]: IMenuItem } = {
	home: {
		path: '/',
		menu: '',
		description: '',
		MenuIcon: PetsIcon,
		skipMenu: true,
		hideBonitace: false,
	},
	profile: {
		path: '/uzivatelsky-profil',
		menu: 'Uživatelský profil',
		description: 'Uživatelský profil',
		MenuIcon: PetsIcon,
		skipMenu: true,
		hideBonitace: false,
	},
	newRegistration: {
		path: '/nova-prihlaska',
		menu: 'Nová přihláška',
		description: 'Ruční vyplnění přihlášky',
		MenuIcon: AddIcon,
		skipMenu: false,
		hideBonitace: false,
	},
	registrations: {
		path: '/prihlasky',
		menu: 'Přihlášky',
		description: 'Přihlášky na výstavu',
		MenuIcon: PetsIcon,
		skipMenu: false,
		hideBonitace: false,
	},
	show: {
		path: '/vystava',
		menu: 'Výstava',
		description: 'Vystavovaní psi',
		MenuIcon: EmojiEventsIcon,
		skipMenu: false,
		hideBonitace: false,
	},
	results: {
		path: '/vysledky',
		menu: 'Výsledky',
		description: 'Výsledky výstavy',
		MenuIcon: BarChartIcon,
		skipMenu: false,
		hideBonitace: true,
	},
	print: {
		path: '/tisk',
		menu: 'Tisk',
		description: 'Tisk',
		MenuIcon: PrintIcon,
		skipMenu: false,
		hideBonitace: false,
	},
	settings: {
		path: '/nastaveni',
		menu: 'Nastavení',
		description: 'Nastavení',
		MenuIcon: SettingsIcon,
		skipMenu: false,
		hideBonitace: false,
		children: [
			{
				path: '/nastaveni/soutez',
				title: 'Soutěž',
			},
			{
				path: '/nastaveni/rozhodci',
				title: 'Rozhodčí',
			},
			{
				path: '/nastaveni/razeni-psu',
				title: 'Řazení psů',
			},
			{
				path: '/nastaveni/docx-sablony',
				title: 'DOCX šablony',
			},
			{
				path: '/nastaveni/email-sablony',
				title: 'Email šablony',
			},
			{
				path: '/nastaveni/nova-soutez',
				title: 'Nová soutěž',
			},
		],
	},
};
