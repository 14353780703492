import { FC, useEffect, useState } from 'react';
import { Formik } from 'formik';
import { mutate } from 'swr';

import {
	Save as SaveIcon,
	Visibility as VisibilityIcon,
	VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';
import {
	Box,
	Button,
	Container,
	FormControl,
	IconButton,
	Input,
	InputAdornment,
	InputLabel,
	TextField,
	Typography,
} from '@mui/material';

import showMessage from '../../utils/showMessage';
import put from '../../libs/put';
import { useStatus } from '../../components/StatusContext';

const ProfilePage: FC = () => {
	useEffect(() => {
		document.title = 'Uživatelský profil';
	}, []);

	const { username } = useStatus();
	const [showPassword, setShowPassword] = useState(false);

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	return (
		<Formik
			initialValues={{
				username,
				currentPassword: '',
				newPassword: '',
				newPassword2: '',
			}}
			onSubmit={async (values, { resetForm }) => {
				try {
					const response = await put(`/api/users/profile`, values);
					if (response.status === 204) {
						mutate('/api/users/profile');
						showMessage({
							msg: `Heslo bylo změněno.`,
						});
						resetForm();
					} else {
						console.log(response.data);
						showMessage({
							msg: `Chyba při změně hesla: ${response.data.error}`,
							severity: 'error',
							duration: 60000,
						});
					}
				} catch (e: any) {
					console.log(e);
					showMessage({
						msg: `Chyba při změně hesla: ${e.message}`,
						severity: 'error',
						duration: 60000,
					});
				}
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => {
				return (
					<Container maxWidth="md">
						<Box
							sx={{
								my: 3,
								px: 5,
								pt: 4,
								pb: 1,
								borderRadius: '10px',
								border: 1,
								borderColor: 'divider',
								backgroundColor: 'background.paper',
							}}
						>
							<form onSubmit={handleSubmit}>
								<Box width="50%">
									<Typography variant="h6">E-mail (nelze změnit)</Typography>
									<Box p={3}>
										<TextField
											id="username"
											value={values.username}
											// onChange={handleChange}
											margin="normal"
											variant="standard"
											fullWidth
											autoComplete="email"
											disabled
										/>
									</Box>
									<Typography variant="h6">Změna hesla</Typography>
									<Box p={3}>
										<TextField
											id="currentPassword"
											label="Aktuální heslo"
											value={values.currentPassword}
											onChange={handleChange}
											margin="normal"
											variant="standard"
											fullWidth
											type="password"
											autoComplete="current-password"
										/>
										<FormControl
											variant="standard"
											sx={{
												mt: 2,
												mb: 1,
												width: '100%',
											}}
										>
											<InputLabel htmlFor="newPassword">
												Nové heslo
											</InputLabel>
											<Input
												id="newPassword"
												value={values.newPassword}
												onChange={handleChange}
												type={showPassword ? 'text' : 'password'}
												autoComplete="new-password"
												endAdornment={
													<InputAdornment position="end">
														<IconButton
															aria-label="Přepnout viditelnost hesla"
															onClick={handleClickShowPassword}
															onMouseDown={handleMouseDownPassword}
															size="large"
														>
															{showPassword ? (
																<VisibilityIcon />
															) : (
																<VisibilityOffIcon />
															)}
														</IconButton>
													</InputAdornment>
												}
											/>
										</FormControl>
										<TextField
											id="newPassword2"
											label="Nové heslo (znova)"
											value={values.newPassword2}
											onChange={handleChange}
											type={showPassword ? 'text' : 'password'}
											margin="normal"
											fullWidth
											autoComplete="new-password"
											variant="standard"
										/>
									</Box>
								</Box>

								<Box p={3} display="flex" justifyContent="flex-end">
									<Button
										type="submit"
										disabled={isSubmitting}
										color="primary"
										startIcon={<SaveIcon />}
										variant="contained"
									>
										Uložit
									</Button>
								</Box>
							</form>
						</Box>
					</Container>
				);
			}}
		</Formik>
	);
};

export default ProfilePage;
