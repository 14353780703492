import { useEffect, FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import useSWR from 'swr';

import { CssBaseline, Box, useTheme } from '@mui/material';

import { menu } from './menu';
import { StatusProvider } from './components/StatusContext';
import ProtectedNavRoute from './components/ProtectedNavRoute';

import ForgottenPasswordPage from './pages/ForgottenPasswordPage';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import NewPasswordPage from './pages/NewPasswordPage';
import NewRegistrationPage from './pages/NewRegistrationPage';
import PrintPage from './pages/PrintPage';
import ProfilePage from './pages/ProfilePage';
import RegistrationsPage from './pages/RegistrationsPage';
import ResultsPage from './pages/ResultsPage';
import ShowPage from './pages/ShowPage';
import ShowSettings from './pages/SettingsPage/ShowSettings';
import Judges from './pages/SettingsPage/Judges';
import ChangeEvaluationOrder from './pages/SettingsPage/ChangeEvaluationOrder';
import ChangeDogOrder from './pages/SettingsPage/ChangeDogOrder';
import DocxTemplates from './pages/SettingsPage/DocxTemplates';
import EmailTemplates from './pages/SettingsPage/EmailTemplates';
import NewShow from './pages/SettingsPage/NewShow';

const App: FC = () => {
	const theme = useTheme();

	useEffect(() => {
		if (process.env.NODE_ENV === 'development') {
			// @ts-ignore
			window.theme = theme;
		}
	}, [theme]);

	const { data } = useSWR('/api/status', {
		revalidateOnFocus: false,
		dedupingInterval: 60000,
	});

	return (
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />

			{!data ? (
				<Box
					sx={{
						minHeight: 64,
						width: '100%',
						backgroundColor: (theme) => theme.palette.primary.main,
					}}
				/>
			) : (
				<StatusProvider
					value={{
						username: data.username,
						isLoggedIn: !!data.username,
						isBonitace: data.bonitace ?? false,
					}}
				>
					<Routes>
						<Route path="/login" element={<LoginPage />} />

						<Route path="/zapomenute-heslo" element={<ForgottenPasswordPage />} />

						<Route path="/nove-heslo" element={<NewPasswordPage />} />

						<Route element={<ProtectedNavRoute />}>
							<Route index element={<HomePage />} />
							<Route path={menu.profile.path} element={<ProfilePage />} />
							<Route
								path={menu.newRegistration.path}
								element={<NewRegistrationPage />}
							/>
							<Route
								path={`${menu.registrations.path}/*`}
								element={<RegistrationsPage />}
							/>
							<Route path={`${menu.show.path}/*`} element={<ShowPage />} />
							<Route path={menu.results.path} element={<ResultsPage />} />
							<Route path={`${menu.print.path}/*`} element={<PrintPage />} />
							<Route path={menu.settings.path}>
								<Route
									path={menu.settings.children![0].path}
									element={<ShowSettings />}
								/>
								<Route
									path={menu.settings.children![1].path}
									element={<Judges />}
								/>
								<Route
									path={menu.settings.children![2].path}
									element={
										data.bonitace ? (
											<ChangeEvaluationOrder />
										) : (
											<ChangeDogOrder />
										)
									}
								/>
								<Route
									path={menu.settings.children![3].path}
									element={<DocxTemplates />}
								/>
								<Route
									path={menu.settings.children![4].path}
									element={<EmailTemplates />}
								/>
								<Route
									path={menu.settings.children![5].path}
									element={<NewShow />}
								/>
							</Route>
						</Route>

						<Route path="*" element={<p>404 - Stránka nenalezena</p>} />
					</Routes>
				</StatusProvider>
			)}
		</Box>
	);
};

export default App;
