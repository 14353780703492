import { FC } from 'react';

import { Box } from '@mui/material';

import TemplateButtonsPanel from './TemplateButtonsPanel';

const ShowDocxTemplates: FC = () => {
	return (
		<>
			<Box m={1} mt={3}>
				<TemplateButtonsPanel
					title="Karty psů"
					currentUrl="/api/templates/dog-cards/current"
					defaultUrl="/api/templates/dog-cards/default"
					uploadUrl="/api/templates/dog-cards"
				/>
			</Box>

			<Box m={1} mt={3}>
				<TemplateButtonsPanel
					title="Katalog psů"
					currentUrl="/api/templates/dogs-catalogue/current"
					defaultUrl="/api/templates/dogs-catalogue/default"
					uploadUrl="/api/templates/dogs-catalogue"
				/>
			</Box>

			<Box m={1} mt={3}>
				<TemplateButtonsPanel
					title="Kniha rozhodčího"
					currentUrl="/api/templates/judge-book/current"
					defaultUrl="/api/templates/judge-book/default"
					uploadUrl="/api/templates/judge-book"
				/>
			</Box>

			<Box m={1} mt={3} pb={3}>
				<TemplateButtonsPanel
					title="Výsledky výstavy"
					currentUrl="/api/templates/show-results/current"
					defaultUrl="/api/templates/show-results/default"
					uploadUrl="/api/templates/show-results"
				/>
			</Box>
		</>
	);
};

export default ShowDocxTemplates;
