import { FormControl, Grid, MenuItem, Select } from '@mui/material';
import React from 'react';
import useSWR, { mutate } from 'swr';
import put from '../../../libs/put';
import { IDogSort, IJudge } from '../../../types';
import { convertPohlavi2, convertSrst, convertTrida2 } from '../../../utils';
import showMessage from '../../../utils/showMessage';

interface RowProps {
	dogSort: IDogSort;
	judges: IJudge[];
}

const Row: React.FC<RowProps> = ({ dogSort, judges }) => {
	return (
		<>
			<Grid item xs={3}>
				{convertTrida2(dogSort.trida)}
			</Grid>
			<Grid item xs={3}>
				{convertPohlavi2(dogSort.pohlavi)}
			</Grid>
			<Grid item xs={3}>
				{convertSrst(dogSort.srst).toLowerCase()}
			</Grid>
			<Grid item xs={3}>
				<FormControl variant="standard" fullWidth>
					<Select
						value={dogSort.judgeId || ''}
						onChange={async (event) => {
							const judgeId = event.target.value;

							try {
								const response = await put(
									`/api/settings/dog-sorting/${dogSort.id}/assign-judge`,
									{
										judgeId,
									},
								);
								if (response.status === 204) {
									mutate('/api/settings/dog-sorting');
									showMessage({
										msg: `Rozhodčí přiřazen.`,
									});
								}
							} catch (e) {
								showMessage({
									msg: `Chyba při přiřazování rozhodčího.`,
									severity: 'error',
									duration: 60000,
								});
							}
						}}
					>
						{judges.map((judge) => (
							<MenuItem value={judge.id} key={judge.id}>{judge.name}</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>
		</>
	);
};

interface JudgesDogAssignPanelProps {
	judges: IJudge[];
}

const JudgesDogAssignPanel: React.FC<JudgesDogAssignPanelProps> = ({ judges }) => {
	const { data } = useSWR<IDogSort[]>('/api/settings/dog-sorting', { refreshInterval: 0 });

	if (!data) {
		return null;
	}

	return (
		<Grid container>
			<Grid item xs={3}>
				<strong>Třída</strong>
			</Grid>
			<Grid item xs={3}>
				<strong>Pohlaví</strong>
			</Grid>
			<Grid item xs={3}>
				<strong>Srst</strong>
			</Grid>
			<Grid item xs={3}>
				<strong>Rozhodčí</strong>
			</Grid>

			{data?.map((item) => (
				<Row key={item.id} dogSort={item} judges={judges} />
			))}
		</Grid>
	);
};

export default JudgesDogAssignPanel;
