import { useState, FC, ReactNode } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { styled } from '@mui/material';

import Menu from './Menu';
import Navbar from './Navbar';
import { useStatus } from './StatusContext';

const MainContent = styled('main')(({ theme }) => ({
	flexGrow: 1,
}));

const DivContentSpacer = styled('div')(({ theme }) => ({
	...theme.mixins.toolbar,
}));

const ProtectedNavRoute: FC<{ children?: ReactNode }> = ({ children }) => {
	const [isMenuOpen, setMenuOpen] = useState(true);
	const { isLoggedIn } = useStatus();

	if (!isLoggedIn) {
		return <Navigate to="/login" />;
	}

	return (
		<>
			<Navbar onOpenMenu={() => setMenuOpen(true)} isMenuOpen={isMenuOpen} />

			<Menu isOpen={isMenuOpen} onHide={() => setMenuOpen(false)} />

			<MainContent>
				<DivContentSpacer />
				{children ? children : <Outlet />}
			</MainContent>
		</>
	);
};

export default ProtectedNavRoute;
