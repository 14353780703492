import { FC } from 'react';
import useSWR from 'swr';

import {
	styled,
	Table,
	TableBody,
	TableCell,
	TableCellProps,
	TableHead,
	TableRow,
} from '@mui/material';

import { IEvaluationStatistics } from '../../types';
import { pohlavi as listPohlavi } from '../../utils';
import { TypBonitace } from '../../enums';

function getCountFor(
	statistics: IEvaluationStatistics['statistics'],
	pohlavi: string,
	typBonitace: TypBonitace,
) {
	for (const stat of statistics) {
		if (pohlavi === stat.pohlavi && typBonitace === stat.typBonitace) {
			return stat.count;
		}
	}
	return 0;
}

const HeaderCell = styled((props) => <TableCell align="center" {...props} />)<TableCellProps>(
	({ theme }) => ({
		backgroundColor: theme.palette.grey[200],
		color: theme.palette.getContrastText(theme.palette.grey[200]),
	}),
);

const UnapprovedCell = styled(TableCell)<TableCellProps>(({ theme }) => ({
	backgroundColor: theme.palette.warning.light,
	color: theme.palette.warning.contrastText,
	borderTop: `1px solid ${theme.palette.grey[300]}`,
	borderBottom: 0,
}));

const EvaluationStatisticsPanel: FC = () => {
	const { data } = useSWR<IEvaluationStatistics>('/api/dogs/evaluation-statistics');

	if (!data) {
		return null;
	}

	let bonitaceCelkem = 0;
	let prebonitaceCelkem = 0;

	return (
		<Table>
			<TableHead>
				<TableRow>
					<HeaderCell>Pohlaví</HeaderCell>
					<HeaderCell
						sx={{ borderLeft: (theme) => `1px solid ${theme.palette.grey[300]}` }}
					>
						Bonitace
					</HeaderCell>
					<HeaderCell>Přebonitace</HeaderCell>
					<HeaderCell
						sx={{ borderLeft: (theme) => `1px solid ${theme.palette.grey[300]}` }}
					>
						{' '}
						Celkem{' '}
					</HeaderCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{listPohlavi.map((pohlavi) => {
					const bonitace = getCountFor(
						data.statistics,
						pohlavi.value,
						TypBonitace.BONITACE,
					);
					const prebonitace = getCountFor(
						data.statistics,
						pohlavi.value,
						TypBonitace.PREBONITACE,
					);

					bonitaceCelkem += bonitace;
					prebonitaceCelkem += prebonitace;

					return (
						<TableRow key={pohlavi.value}>
							<TableCell>{pohlavi.label}</TableCell>
							<TableCell
								align="center"
								sx={{
									borderLeft: (theme) => `1px solid ${theme.palette.grey[300]}`,
								}}
							>
								{bonitace}
							</TableCell>
							<TableCell align="center">{prebonitace}</TableCell>
							<TableCell
								align="center"
								sx={{
									borderLeft: (theme) => `1px solid ${theme.palette.grey[300]}`,
								}}
							>
								{bonitace + prebonitace}
							</TableCell>
						</TableRow>
					);
				})}

				<TableRow>
					<TableCell sx={{ borderBottom: 0 }}>
						<strong>Celkem</strong>
					</TableCell>
					<TableCell
						align="center"
						sx={{
							borderLeft: (theme) => `1px solid ${theme.palette.grey[300]}`,
							borderBottom: 0,
						}}
					>
						{bonitaceCelkem}
					</TableCell>
					<TableCell align="center" sx={{ borderBottom: 0 }}>
						{prebonitaceCelkem}
					</TableCell>
					<TableCell
						align="center"
						sx={{
							borderLeft: (theme) => `1px solid ${theme.palette.grey[300]}`,
							borderBottom: 0,
						}}
					>
						{bonitaceCelkem + prebonitaceCelkem}
					</TableCell>
				</TableRow>

				{data.unapprovedRegistrationsCount > 0 && (
					<TableRow>
						<UnapprovedCell colSpan={3}>Neschválených přihlášek</UnapprovedCell>
						<UnapprovedCell align="center">
							{data.unapprovedRegistrationsCount}
						</UnapprovedCell>
					</TableRow>
				)}
			</TableBody>
		</Table>
	);
};

export default EvaluationStatisticsPanel;
