export default async function upload<T = any>(
	input: RequestInfo,
	formData: FormData,
): Promise<{
	ok: boolean;
	data: T;
	status: number;
	statusText: string;
}> {
	const response = await fetch(input, {
		method: 'POST',
		body: formData,
	});

	let responseData;
	if (response.status === 200) {
		responseData = await response.json();
	} else {
		responseData = await response.text();
	}

	return {
		ok: response.ok,
		data: responseData,
		status: response.status,
		statusText: response.statusText,
	};
}
