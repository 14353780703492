import { FC, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';

import { LockOutlined as LockOutlinedIcon } from '@mui/icons-material';
import {
	AppBar,
	Avatar,
	Box,
	Button,
	Container,
	CssBaseline,
	FormControl,
	Input,
	InputLabel,
	Toolbar,
	Typography,
	Alert,
} from '@mui/material';

import post from '../libs/post';

const ForgottenPasswordPage: FC = () => {
	const [errorMessage, setErrorMessage] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		document.title = 'Výstava psů - Zapomenuté heslo';
	}, []);

	return (
		<>
			<AppBar position="fixed">
				<Toolbar>
					<Typography
						variant="h6"
						component={Link}
						to="/"
						sx={{
							color: (theme) => theme.palette.common.white,
							textDecoration: 'none',
						}}
					>
						Výstava psů
					</Typography>
				</Toolbar>
			</AppBar>
			<Container maxWidth="sm" sx={{ mt: 5 }}>
				<CssBaseline />
				<Box
					sx={{
						mx: 'auto',
						mt: 8,
						p: 2,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						borderRadius: 2,
						border: 1,
						borderColor: 'divider',
					}}
				>
					<Avatar
						sx={{
							mt: 4,
							mb: 2,
							backgroundColor: 'secondary.main',
						}}
					>
						<LockOutlinedIcon />
					</Avatar>

					<Typography component="h1" variant="h5">
						Zapomenuté heslo
					</Typography>

					<Formik
						initialValues={{ email: '' }}
						onSubmit={async ({ email }) => {
							setErrorMessage(null);
							const response = await post('/api/users/forgotten-password', {
								email,
							});

							if (response.status === 204) {
								navigate('/');
							} else {
								setErrorMessage(response.data.error);
							}
						}}
					>
						{({ values, isSubmitting, handleChange, handleBlur, handleSubmit }) => {
							return (
								<form
									onSubmit={handleSubmit}
									style={{
										width: '100%',
										maxWidth: 420,
									}}
								>
									<FormControl
										margin="normal"
										required
										fullWidth
										sx={{
											mt: 6,
											mb: 1,
										}}
									>
										<InputLabel htmlFor="email">Zadejte Váš e-mail</InputLabel>
										<Input
											id="email"
											name="email"
											autoFocus
											value={values.email}
											onChange={(e) => {
												setErrorMessage(null);
												handleChange(e);
											}}
											onBlur={handleBlur}
											autoComplete="email"
										/>
										{errorMessage && (
											<Alert severity="error" sx={{ mt: 1 }}>
												{errorMessage}
											</Alert>
										)}
									</FormControl>

									<Box
										sx={{
											mt: 7,
											mb: 3,
											display: 'flex',
											justifyContent: 'flex-end',
										}}
									>
										<Button
											type="submit"
											variant="contained"
											color="primary"
											disabled={isSubmitting}
										>
											Poslat na e-mail
										</Button>
									</Box>
								</form>
							);
						}}
					</Formik>
				</Box>
			</Container>
		</>
	);
};

export default ForgottenPasswordPage;
