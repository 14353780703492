import { FC, ReactNode } from 'react';

import { Alert, Box } from '@mui/material';

interface EmptyDataProps {
	children: ReactNode;
}

const EmptyData: FC<EmptyDataProps> = ({ children }) => (
	<Box display="flex" justifyContent="center" alignItems="center" minHeight="40vh">
		<Alert severity="info">{children}</Alert>
	</Box>
);

export default EmptyData;
