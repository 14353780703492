/**
 * Example: 7. 4. 2021 v 02:13:05
 */
function format(d: Date) {
	return `${d.getDate()}. ${d.getMonth() + 1}. ${d.getFullYear()} v ${('0' + d.getHours()).slice(
		-2,
	)}:${('0' + d.getMinutes()).slice(-2)}:${('0' + d.getSeconds()).slice(-2)}`;
}

export function getCurrentDate() {
	return format(new Date());
}

export function formatDate(input?: string | null) {
	if (!input) {
		return '';
	}

	return format(new Date(input));
}
