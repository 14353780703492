import { useEffect } from 'react';

import { Box, Button, Container } from '@mui/material';

import { useStatus } from '../../components/StatusContext';

const PrintPage = () => {
	const { isBonitace } = useStatus();

	useEffect(() => {
		document.title = 'Tisk - Výstava psů';
	}, []);

	return (
		<Container maxWidth="md">
			<Box
				sx={{
					my: 3,
					px: 5,
					py: 2,
					paddingBottom: '32px',
					borderRadius: '10px',
					border: 1,
					borderColor: 'divider',
					backgroundColor: 'background.paper',
				}}
			>
				{!isBonitace && (
					<Box m={1} mt={3}>
						<h3>Karty psů</h3>
						<Button
							variant="outlined"
							color="primary"
							download
							href="api/docx-print/cards"
						>
							Word (.docx)
						</Button>
					</Box>
				)}

				{isBonitace && (
					<Box m={1} mt={3}>
						<h3>Bonitační karty psů</h3>

						<Button
							variant="outlined"
							color="primary"
							download
							href="api/docx-print/evaluation-cards"
						>
							Word (.docx)
						</Button>
					</Box>
				)}

				{!isBonitace && (
					<Box m={1} mt={3}>
						<h3>Katalog psů</h3>
						<Button
							variant="outlined"
							color="primary"
							download
							href="api/docx-print/catalogue"
						>
							Word (.docx)
						</Button>
					</Box>
				)}

				{isBonitace && (
					<Box m={1} mt={3}>
						<h3>Katalog psů</h3>
						<Button
							variant="outlined"
							color="primary"
							download
							href="api/docx-print/evaluation-catalogue"
						>
							Word (.docx)
						</Button>
					</Box>
				)}

				{isBonitace && (
					<Box m={1} mt={3}>
						<h3>Potvrzení o zařazení do chovu</h3>
						<Button
							variant="outlined"
							color="primary"
							download
							href="api/docx-print/confirmation-of-inclusion-in-breeding"
						>
							Word (.docx)
						</Button>
					</Box>
				)}

				{!isBonitace && (
					<Box m={1} mt={3}>
						<h3>Kniha rozhodčího</h3>
						<Button
							variant="outlined"
							color="primary"
							download
							href="api/docx-print/judge-book"
						>
							Word (.docx)
						</Button>
					</Box>
				)}

				{!isBonitace && (
					<Box m={1} mt={3}>
						<h3>Výsledky výstavy</h3>
						<Button
							variant="outlined"
							color="primary"
							download
							href="api/docx-print/show-results"
						>
							Word (.docx)
						</Button>
					</Box>
				)}

				{isBonitace && (
					<Box m={1} mt={3}>
						<h3>Výsledky bonitace</h3>
						<Button
							variant="outlined"
							color="primary"
							download
							href="api/docx-print/evaluation-results"
						>
							Word (.docx)
						</Button>
					</Box>
				)}
			</Box>
		</Container>
	);
};

export default PrintPage;
