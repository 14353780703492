import { useState, useCallback } from 'react';

import { removeDiacritics } from '../../utils/string';
import { Dog } from '../../types';

export type FilterFields = Omit<ReturnType<typeof useFilter>, 'showRow'>;

export const useFilter = () => {
	const [cisloVKatalogu, setCisloVKatalogu] = useState('');
	const [jmeno, setJmeno] = useState('');
	const [cisloZapisu, setCisloZapisu] = useState('');
	const [pohlavi, setPohlavi] = useState('');
	const [srst, setSrst] = useState('');
	const [trida, setTrida] = useState('');
	const [majitel, setMajitel] = useState('');

	const showRow = useCallback(
		(row: Dog) => {
			if (
				cisloVKatalogu !== '' &&
				!row.cisloVKatalogu?.toString().includes(cisloVKatalogu.toString())
			) {
				return false;
			}

			if (
				jmeno !== '' &&
				!removeDiacritics(row.jmeno)
					.toLowerCase()
					.includes(removeDiacritics(jmeno).toLowerCase())
			) {
				return false;
			}

			if (
				cisloZapisu !== '' &&
				!removeDiacritics(row.cisloZapisu)
					.toLowerCase()
					.includes(removeDiacritics(cisloZapisu).toLowerCase())
			) {
				return false;
			}

			if (pohlavi !== '' && !row.pohlavi?.includes(pohlavi)) {
				return false;
			}

			if (srst !== '' && row.srst?.toString() !== srst) {
				return false;
			}

			if (trida !== '' && row.trida?.toString() !== trida) {
				return false;
			}

			if (
				majitel !== '' &&
				!removeDiacritics(row.majitelJmeno)
					?.toLowerCase()
					.includes(removeDiacritics(majitel).toLowerCase())
			) {
				return false;
			}

			return true;
		},
		[cisloVKatalogu, cisloZapisu, jmeno, majitel, pohlavi, srst, trida],
	);

	return {
		cisloVKatalogu,
		setCisloVKatalogu,
		jmeno,
		setJmeno,
		cisloZapisu,
		setCisloZapisu,
		pohlavi,
		setPohlavi,
		srst,
		setSrst,
		trida,
		setTrida,
		majitel,
		setMajitel,
		showRow,
	};
};
