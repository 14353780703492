import { Collapse, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { IMenuItem } from '../menu';
import { useState } from 'react';

interface IMenuItemProps {
	isMenuOpen: boolean;
	menuItem: IMenuItem;
}

const MenuItem: React.FC<IMenuItemProps> = ({ isMenuOpen, menuItem }) => {
	const [open, setOpen] = useState(false);
	const location = useLocation();

	const MenuIcon = menuItem.MenuIcon;
	const hasSubmenu = !!menuItem.children;

	return (
		<>
			<ListItemButton
				component={hasSubmenu ? 'div' : Link}
				to={hasSubmenu ? undefined : menuItem.path}
				selected={location.pathname === menuItem.path}
				onClick={hasSubmenu ? () => setOpen(!open) : undefined}
				sx={{
					minHeight: 48,
					justifyContent: isMenuOpen ? 'initial' : 'center',
					px: 2.5,
				}}
			>
				<ListItemIcon
					title={menuItem.menu}
					sx={{
						minWidth: 0,
						mr: isMenuOpen ? 3 : 0,
						justifyContent: 'center',
					}}
				>
					<MenuIcon />
				</ListItemIcon>
				<ListItemText
					primary={menuItem.menu}
					sx={{ display: isMenuOpen ? 'block' : 'none' }}
				/>
				{hasSubmenu && <>{open ? <ExpandLess /> : <ExpandMore />}</>}
			</ListItemButton>

			<Collapse in={open} timeout="auto" unmountOnExit>
				{menuItem.children?.map((child) => (
					<ListItemButton
						component={Link}
						to={child.path}
						selected={location.pathname === child.path}
						key={child.path}
						sx={{
							minHeight: 48,
							justifyContent: isMenuOpen ? 'initial' : 'center',
							px: 2.5,
						}}
					>
						<ListItemText
							inset
							primary={child.title}
							sx={{ display: isMenuOpen ? 'block' : 'none' }}
						/>
					</ListItemButton>
				))}
			</Collapse>
		</>
	);
};

export default MenuItem;
