import { useMemo, useState, FC } from 'react';

import { Box } from '@mui/system';
import {
	MenuItem,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	TextField,
	Select,
	styled,
} from '@mui/material';

import {
	trida as tridaValues,
	pohlavi as pohlaviValues,
	srst as srstValues,
	convertPohlavi,
	convertSrst,
	convertTrida,
} from '../../utils';
import { removeDiacritics } from '../../utils/string';
import { Registration } from '../../types';
import { useStatus } from '../../components/StatusContext';

type Order = 'asc' | 'desc';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator<Key extends keyof any>(
	order: Order,
	orderBy: Key,
): (a: { [key in Key]?: any }, b: { [key in Key]?: any }) => number {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
	const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

const TableCellTableHead1 = styled(TableCell)(({ theme }) => ({
	backgroundColor: theme.palette.background.paper,
	fontWeight: 'bold',
	top: 104,
	height: 58,
}));

const SpanVisuallyHidden = styled('span')(({ theme }) => ({
	border: 0,
	clip: 'rect(0 0 0 0)',
	height: 1,
	margin: -1,
	overflow: 'hidden',
	padding: 0,
	position: 'absolute',
	top: 20,
	width: 1,
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
	overflowX: 'visible',
	paddingBottom: theme.spacing(3),
	backgroundColor: theme.palette.background.default,
}));

const StyledTable = styled(Table)(({ theme }) => ({
	maxWidth: 1300,
	margin: '0 auto',
	padding: theme.spacing(0, 2, 2),
	backgroundColor: theme.palette.background.paper,
	borderRight: `1px solid`,
	borderLeft: `1px solid`,
	borderBottom: `1px solid`,
	borderColor: theme.palette.divider,
	borderBottomLeftRadius: 10,
	borderBottomRightRadius: 10,
}));

const TableCellTableHead2 = styled(TableCell)(({ theme }) => ({
	borderBottom: `4px solid ${theme.palette.primary.main}`,
	backgroundColor: theme.palette.background.paper,
	padding: theme.spacing(1, 1),
	top: 162,
	height: 53,
}));

const TableRowRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.background.default,
	},

	'&:hover': {
		cursor: 'pointer',
		backgroundColor: theme.palette.grey[200],
	},
}));

const TableCellThin = styled(TableCell)(({ theme }) => ({
	width: 50,
}));

const TableCellMajitel = styled(TableCell)(({ theme }) => ({
	width: 320,
}));

interface HeadCell {
	id: keyof Registration;
	label: string;
	style?: React.CSSProperties;
	bonitaceHidden?: boolean;
}

const headCells: HeadCell[] = [
	{ id: 'jmeno', label: 'Jméno', style: { width: '250px' } },
	{ id: 'cisloZapisu', label: 'Číslo zápisu' },
	{ id: 'pohlavi', label: 'Pohlaví' },
	{ id: 'srst', label: 'Srst', bonitaceHidden: true },
	{ id: 'trida', label: 'Třída', style: { width: '120px' }, bonitaceHidden: true },
	{ id: 'majitelJmeno', label: 'Majitel', style: { width: '140px' } },
	{ id: 'vyrizena', label: 'Vyřízená' },
];

interface TableHeadRow1Props {
	onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Registration) => void;
	order: Order;
	orderBy: string;
	isBonitace?: boolean;
}

const TableHeadRow1: FC<TableHeadRow1Props> = ({ order, orderBy, onRequestSort, isBonitace }) => {
	const createSortHandler =
		(property: keyof Registration) => (event: React.MouseEvent<unknown>) => {
			onRequestSort(event, property);
		};

	return (
		<TableRow>
			{headCells.map((headCell) => {
				if (isBonitace && headCell.bonitaceHidden) {
					return null;
				}

				return (
					<TableCellTableHead1 key={headCell.id} style={headCell.style}>
						<TableSortLabel
							active={headCell.id === orderBy}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}

							{orderBy === headCell.id ? (
								<SpanVisuallyHidden>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</SpanVisuallyHidden>
							) : null}
						</TableSortLabel>
					</TableCellTableHead1>
				);
			})}
		</TableRow>
	);
};

interface Props {
	data: Registration[];
	onEdit: (row: Registration) => void;
}

const RegistrationsTable: FC<Props> = ({ data, onEdit }) => {
	const { isBonitace } = useStatus();

	const [order, setOrder] = useState<Order>('desc');
	const [orderBy, setOrderBy] = useState<keyof Registration>('id');

	const [jmeno, setJmeno] = useState('');
	const [cisloZapisu, setCisloZapisu] = useState('');
	const [pohlavi, setPohlavi] = useState('');
	const [srst, setSrst] = useState('');
	const [trida, setTrida] = useState('');
	const [majitel, setMajitel] = useState('');
	const [vyrizena, setVyrizena] = useState('');

	return useMemo(() => {
		const handleRequestSort = (
			event: React.MouseEvent<unknown>,
			property: keyof Registration,
		) => {
			const isAsc = orderBy === property && order === 'asc';
			setOrder(isAsc ? 'desc' : 'asc');
			setOrderBy(property);
		};

		return (
			<StyledTableContainer>
				<Box
					sx={{
						backgroundColor: 'background.default',
						height: 24,
						margin: 'auto',
						width: 1300,
						position: 'sticky',
						top: 64,
					}}
				/>
				<Box
					sx={{
						border: 1,
						borderColor: 'divider',
						backgroundColor: 'background.paper',
						height: 16,
						margin: 'auto',
						width: 1300,
						borderTopLeftRadius: 10,
						borderTopRightRadius: 10,
						borderBottom: 0,
						position: 'sticky',
						top: 88,
						boxShadow: (theme) =>
							`0px -8px 0px 8px ${theme.palette.background.default}`,
					}}
				/>
				<StyledTable stickyHeader>
					<TableHead>
						<TableHeadRow1
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							isBonitace={isBonitace}
						/>

						<TableRow>
							<TableCellTableHead2>
								<TextField
									value={jmeno}
									onChange={(e) => setJmeno(e.target.value)}
									autoComplete="off"
									variant="standard"
									fullWidth
								/>
							</TableCellTableHead2>
							<TableCellTableHead2>
								<TextField
									value={cisloZapisu}
									onChange={(e) => setCisloZapisu(e.target.value)}
									autoComplete="off"
									variant="standard"
									fullWidth
								/>
							</TableCellTableHead2>
							<TableCellTableHead2>
								<Select
									value={pohlavi}
									onChange={(e) => setPohlavi(e.target.value)}
									variant="standard"
									fullWidth
								>
									<MenuItem value="">&nbsp;</MenuItem>
									{pohlaviValues.map((option) => (
										<MenuItem key={option.value} value={option.value}>
											{option.label}
										</MenuItem>
									))}
								</Select>
							</TableCellTableHead2>
							{!isBonitace && (
								<>
									<TableCellTableHead2>
										<Select
											value={srst}
											onChange={(e) => setSrst(e.target.value)}
											variant="standard"
											fullWidth
										>
											<MenuItem value="">&nbsp;</MenuItem>
											{srstValues.map((option) => (
												<MenuItem key={option.value} value={option.value}>
													{option.label}
												</MenuItem>
											))}
										</Select>
									</TableCellTableHead2>
									<TableCellTableHead2>
										<Select
											value={trida}
											onChange={(e) => setTrida(e.target.value)}
											variant="standard"
											fullWidth
										>
											<MenuItem value="">&nbsp;</MenuItem>
											{tridaValues.map((option) => (
												<MenuItem key={option.value} value={option.value}>
													{option.label}
												</MenuItem>
											))}
										</Select>
									</TableCellTableHead2>
								</>
							)}
							<TableCellTableHead2 style={{ width: '140px' }}>
								<TextField
									value={majitel}
									onChange={(e) => setMajitel(e.target.value)}
									autoComplete="off"
									variant="standard"
									fullWidth
								/>
							</TableCellTableHead2>
							<TableCellTableHead2>
								<Select
									value={vyrizena}
									onChange={(e) => setVyrizena(e.target.value)}
									variant="standard"
									fullWidth
								>
									<MenuItem value="">&nbsp;</MenuItem>
									<MenuItem value="1">Ano</MenuItem>
									<MenuItem value="0">Ne</MenuItem>
								</Select>
							</TableCellTableHead2>
						</TableRow>
					</TableHead>

					<TableBody>
						{stableSort<Registration>(data, getComparator(order, orderBy)).map(
							(row) => {
								let show = true;
								if (
									jmeno !== '' &&
									!removeDiacritics(row.jmeno)
										.toLowerCase()
										.includes(removeDiacritics(jmeno).toLowerCase())
								) {
									show = false;
								}

								if (
									cisloZapisu !== '' &&
									!removeDiacritics(row.cisloZapisu)
										.toLowerCase()
										.includes(removeDiacritics(cisloZapisu).toLowerCase())
								) {
									show = false;
								}

								if (pohlavi !== '' && !row.pohlavi?.includes(pohlavi)) {
									show = false;
								}

								if (srst !== '' && row.srst?.toString() !== srst) {
									show = false;
								}

								if (trida !== '' && row.trida?.toString() !== trida) {
									show = false;
								}

								if (
									majitel !== '' &&
									!removeDiacritics(row.majitelJmeno)
										?.toLowerCase()
										.includes(removeDiacritics(majitel).toLowerCase())
								) {
									show = false;
								}

								if (
									vyrizena !== '' &&
									((row.vyrizena && vyrizena === '0') ||
										(!row.vyrizena && vyrizena === '1'))
								) {
									show = false;
								}

								return (
									show && (
										<TableRowRow key={row.id} onClick={() => onEdit(row)}>
											<TableCell>{row.jmeno}</TableCell>

											<TableCell>{row.cisloZapisu}</TableCell>

											<TableCellThin>
												{convertPohlavi(row.pohlavi)}
											</TableCellThin>

											{!isBonitace && (
												<>
													<TableCellThin>
														{convertSrst(row.srst)}
													</TableCellThin>

													<TableCellThin>
														{convertTrida(row.trida)}
													</TableCellThin>
												</>
											)}

											<TableCellMajitel>{row.majitelJmeno}</TableCellMajitel>

											<TableCellThin>
												{row.vyrizena ? 'Ano' : 'Ne'}
											</TableCellThin>
										</TableRowRow>
									)
								);
							},
						)}
					</TableBody>
				</StyledTable>
			</StyledTableContainer>
		);
	}, [
		data,
		onEdit,
		jmeno,
		cisloZapisu,
		pohlavi,
		srst,
		trida,
		majitel,
		vyrizena,
		order,
		orderBy,
		isBonitace,
	]);
};

export default RegistrationsTable;
