import { createContext, useContext, FC, ReactNode } from 'react';

interface IStatusContext {
	username?: string | null;
	isLoggedIn: boolean;
	isBonitace: boolean;
}

const StatusContext = createContext<IStatusContext>({
	isLoggedIn: false,
	isBonitace: false,
});

interface IStatusProviderProps {
	value: IStatusContext;
	children: ReactNode;
}

export const StatusProvider: FC<IStatusProviderProps> = ({ value, children }) => {
	return <StatusContext.Provider value={value}>{children}</StatusContext.Provider>;
};

export const useStatus = () => useContext(StatusContext);
