import { useState, FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import { Drawer } from '@mui/material';

import { Dog } from '../../types';
import ShowTable from './ShowTable';
import Form from './ShowForm';
import Loading from '../../components/Loading';
import { menu } from '../../menu';
import EmptyData from '../../components/EmptyData';

const ShowPage: FC = () => {
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		document.title = 'Výstava - Výstava psů';
	}, []);

	const [selectedRow, setSelectedRow] = useState<Dog | null>(null);

	const { data } = useSWR<Dog[]>('/api/dogs/event');

	useEffect(() => {
		if (location.pathname !== menu.show.path) {
			const dogId = parseInt(location.pathname.split('/').pop() ?? '', 10);
			if (!isNaN(dogId) && data) {
				const dogRow = data.find((dog) => dog.id === dogId);
				if (dogRow) {
					setSelectedRow(dogRow);
				}
			}
		}
	}, [location, data]);

	const handleCloseBlade = () => {
		navigate(menu.show.path);
		setSelectedRow(null);
	};

	if (!data) {
		return <Loading />;
	}

	if (data.length === 0) {
		return <EmptyData>Nemáte žádné schválené přihlášky</EmptyData>;
	}

	return (
		<>
			<ShowTable
				data={data}
				onEdit={(row) => {
					navigate(`${menu.show.path}/${row.id}`);
					setSelectedRow(row);
				}}
			/>

			<Drawer open={!!selectedRow} onClose={handleCloseBlade} anchor="right">
				<Form data={selectedRow!} onClose={handleCloseBlade} />
			</Drawer>
		</>
	);
};

export default ShowPage;
