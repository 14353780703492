import { FormControl, Grid, MenuItem, Select } from '@mui/material';
import React from 'react';
import useSWR, { mutate } from 'swr';

import put from '../../../libs/put';
import { IEvaluationSort, IJudge } from '../../../types';
import { convertPohlavi2, convertTypBonitace } from '../../../utils';
import showMessage from '../../../utils/showMessage';

interface RowProps {
	evaluationSort: IEvaluationSort;
	judges: IJudge[];
}

const Row: React.FC<RowProps> = ({ evaluationSort, judges }) => {
	return (
		<>
			<Grid item xs={4}>
				{convertPohlavi2(evaluationSort.pohlavi)}
			</Grid>
			<Grid item xs={4}>
				{convertTypBonitace(evaluationSort.typBonitace).toLowerCase()}
			</Grid>
			<Grid item xs={4}>
				<FormControl variant="standard" fullWidth>
					<Select
						value={evaluationSort.judgeId || ''}
						onChange={async (event) => {
							const judgeId = event.target.value;

							try {
								const response = await put(
									`/api/settings/evaluation-sorting/${evaluationSort.id}/assign-judge`,
									{
										judgeId,
									},
								);
								if (response.status === 204) {
									mutate('/api/settings/evaluation-sorting');
									showMessage({
										msg: `Rozhodčí přiřazen.`,
									});
								}
							} catch (e) {
								showMessage({
									msg: `Chyba při přiřazování rozhodčího.`,
									severity: 'error',
									duration: 60000,
								});
							}
						}}
					>
						{judges.map((judge) => (
							<MenuItem value={judge.id} key={judge.id}>
								{judge.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>
		</>
	);
};

interface JudgesEvaluationAssignPanelProps {
	judges: IJudge[];
}

const JudgesEvaluationAssignPanel: React.FC<JudgesEvaluationAssignPanelProps> = ({ judges }) => {
	const { data } = useSWR<IEvaluationSort[]>('/api/settings/evaluation-sorting', {
		refreshInterval: 0,
	});

	if (!data) {
		return null;
	}

	return (
		<Grid container>
			<Grid item xs={4}>
				<strong>Pohlaví</strong>
			</Grid>
			<Grid item xs={4}>
				<strong>Typ bonitace</strong>
			</Grid>
			<Grid item xs={4}>
				<strong>Rozhodčí</strong>
			</Grid>

			{data?.map((item) => (
				<Row key={item.id} evaluationSort={item} judges={judges} />
			))}
		</Grid>
	);
};

export default JudgesEvaluationAssignPanel;
