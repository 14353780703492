import { FC } from 'react';

import { Box } from '@mui/material';

import TemplateButtonsPanel from './TemplateButtonsPanel';

const EvaluationDocxTemplates: FC = () => {
	return (
		<>
			<Box m={1} mt={3}>
				<TemplateButtonsPanel
					title="Bonitační karty psů"
					currentUrl="/api/templates/evaluation-cards/current"
					defaultUrl="/api/templates/evaluation-cards/default"
					uploadUrl="/api/templates/evaluation-cards"
				/>
			</Box>

			<Box m={1} mt={3}>
				<TemplateButtonsPanel
					title="Katalog psů"
					currentUrl="/api/templates/evaluation-catalogue/current"
					defaultUrl="/api/templates/evaluation-catalogue/default"
					uploadUrl="/api/templates/evaluation-catalogue"
				/>
			</Box>

			<Box m={1} mt={3} pb={3}>
				<TemplateButtonsPanel
					title="Výsledky bonitace"
					currentUrl="/api/templates/evaluation-results/current"
					defaultUrl="/api/templates/evaluation-results/default"
					uploadUrl="/api/templates/evaluation-results"
				/>
			</Box>
		</>
	);
};

export default EvaluationDocxTemplates;
