export enum Pohlavi {
	FENA = 'F',
	PES = 'P',
}

export enum Srst {
	KRATKA = 'K',
	DLOUHA = 'D',
}

export enum Trida {
	DOROST = 1,
	MLADYCH = 2,
	DOSPIVAJICICH = 3,
	PRACOVNI = 4,
	VETERANI = 5,
}

export enum TypBonitace {
	BONITACE = 'B',
	PREBONITACE = 'P',
}
