import { FC, useEffect } from 'react';

import { Box, Container } from '@mui/material';

import StatisticsPanel from './StatisticsPanel';
import EvaluationStatisticsPanel from './EvaluationStatisticsPanel';
import { useStatus } from '../../components/StatusContext';

const HomePage: FC = () => {
	const { isBonitace } = useStatus();

	useEffect(() => {
		document.title = 'Výstava psů';
	}, []);

	return (
		<Container maxWidth="md">
			<Box
				sx={{
					my: 3,
					px: 5,
					py: 2,
					paddingBottom: '32px',
					borderRadius: '10px',
					border: 1,
					borderColor: 'divider',
					backgroundColor: 'background.paper',
				}}
			>
				<h3>Statistika</h3>

				{isBonitace ? <EvaluationStatisticsPanel /> : <StatisticsPanel />}
			</Box>
		</Container>
	);
};

export default HomePage;
