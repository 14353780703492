import { FC } from 'react';

import {
	MenuItem,
	Select,
	TableCell,
	TableHead as MuiTableHead,
	TableRow,
	TableSortLabel,
	TextField,
	styled,
} from '@mui/material';

import { trida as tridaValues, pohlavi as pohlaviValues, srst as srstValues } from '../../utils';
import { Dog } from '../../types';
import { FilterFields } from './useFilter';

const TableHeadCell1 = styled(TableCell)(({ theme }) => ({
	backgroundColor: theme.palette.background.paper,
	fontWeight: 'bold',
	top: 104,
	height: 58,
}));

const TableHeadCell2 = styled(TableCell)(({ theme }) => ({
	borderBottom: `4px solid ${theme.palette.primary.main}`,
	backgroundColor: theme.palette.background.paper,
	padding: theme.spacing(1, 1),
	top: 162,
	height: 53,
}));

type Order = 'asc' | 'desc';

interface HeadCell {
	id: keyof Dog;
	label: string;
	style?: React.CSSProperties;
	bonitaceHidden?: boolean;
}

const headCells: HeadCell[] = [
	{ id: 'cisloVKatalogu', label: 'Kat. č.' },
	{ id: 'jmeno', label: 'Jméno' },
	{ id: 'cisloZapisu', label: 'Číslo zápisu' },
	{ id: 'pohlavi', label: 'Pohlaví' },
	{ id: 'srst', label: 'Srst', bonitaceHidden: true },
	{ id: 'trida', label: 'Třída', bonitaceHidden: true },
	{ id: 'majitelJmeno', label: 'Majitel' },
];

interface Props {
	onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Dog) => void;
	order: Order;
	orderBy: string;
	filterFields: FilterFields;
	isBonitace: boolean;
}

const TableHead: FC<Props> = ({ order, orderBy, onRequestSort, filterFields, isBonitace }) => {
	const createSortHandler = (property: keyof Dog) => (event: React.MouseEvent<unknown>) => {
		onRequestSort(event, property);
	};

	return (
		<MuiTableHead>
			<TableRow>
				{headCells.map((headCell) => {
					if (isBonitace && headCell.bonitaceHidden) {
						return null;
					}

					return (
						<TableHeadCell1 key={headCell.id} style={headCell.style}>
							<TableSortLabel
								active={headCell.id === orderBy}
								direction={orderBy === headCell.id ? order : 'asc'}
								onClick={createSortHandler(headCell.id)}
							>
								{headCell.label}
							</TableSortLabel>
						</TableHeadCell1>
					);
				})}
			</TableRow>

			<TableRow>
				<TableHeadCell2>
					<TextField
						value={filterFields.cisloVKatalogu}
						onChange={(e) => filterFields.setCisloVKatalogu(e.target.value)}
						autoComplete="off"
						variant="standard"
						fullWidth
					/>
				</TableHeadCell2>
				<TableHeadCell2>
					<TextField
						value={filterFields.jmeno}
						onChange={(e) => filterFields.setJmeno(e.target.value)}
						autoComplete="off"
						variant="standard"
						fullWidth
					/>
				</TableHeadCell2>
				<TableHeadCell2>
					<TextField
						value={filterFields.cisloZapisu}
						onChange={(e) => filterFields.setCisloZapisu(e.target.value)}
						autoComplete="off"
						variant="standard"
						fullWidth
					/>
				</TableHeadCell2>
				<TableHeadCell2>
					<Select
						value={filterFields.pohlavi}
						onChange={(e) => filterFields.setPohlavi(e.target.value as string)}
						variant="standard"
						fullWidth
					>
						<MenuItem value="">&nbsp;</MenuItem>
						{pohlaviValues.map((option) => (
							<MenuItem key={option.value} value={option.value}>
								{option.label}
							</MenuItem>
						))}
					</Select>
				</TableHeadCell2>

				{!isBonitace && (
					<>
						<TableHeadCell2>
							<Select
								value={filterFields.srst}
								onChange={(e) => filterFields.setSrst(e.target.value as string)}
								variant="standard"
								fullWidth
							>
								<MenuItem value="">&nbsp;</MenuItem>
								{srstValues.map((option) => (
									<MenuItem key={option.value} value={option.value}>
										{option.label}
									</MenuItem>
								))}
							</Select>
						</TableHeadCell2>
						<TableHeadCell2>
							<Select
								value={filterFields.trida}
								onChange={(e) => filterFields.setTrida(e.target.value as string)}
								variant="standard"
								fullWidth
							>
								<MenuItem value="">&nbsp;</MenuItem>
								{tridaValues.map((option) => (
									<MenuItem key={option.value} value={option.value}>
										{option.label}
									</MenuItem>
								))}
							</Select>
						</TableHeadCell2>
					</>
				)}

				<TableHeadCell2 style={{ width: '140px' }}>
					<TextField
						value={filterFields.majitel}
						onChange={(e) => filterFields.setMajitel(e.target.value)}
						autoComplete="off"
						variant="standard"
						fullWidth
					/>
				</TableHeadCell2>
			</TableRow>
		</MuiTableHead>
	);
};

export default TableHead;
