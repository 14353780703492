import { FC, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';

import {
	LockOutlined as LockOutlinedIcon,
	Save as SaveIcon,
	Visibility as VisibilityIcon,
	VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';
import {
	AppBar,
	Avatar,
	Box,
	Button,
	CssBaseline,
	FormControl,
	Input,
	InputLabel,
	Toolbar,
	Typography,
	InputAdornment,
	IconButton,
	TextField,
	Container,
	Alert,
} from '@mui/material';

import post from '../libs/post';

const NewPasswordPage: FC = () => {
	useEffect(() => {
		document.title = 'Výstava psů - Nové heslo';
	}, []);

	const code = new URLSearchParams(window.location.search).get('kod');
	const navigate = useNavigate();
	const [showPassword, setShowPassword] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	return (
		<>
			<AppBar position="fixed">
				<Toolbar>
					<Typography
						variant="h6"
						component={Link}
						to="/"
						sx={{
							color: (theme) => theme.palette.common.white,
							textDecoration: 'none',
						}}
					>
						Výstava psů
					</Typography>
				</Toolbar>
			</AppBar>
			<Container maxWidth="sm" sx={{ mt: 5 }}>
				<CssBaseline />
				<Box
					sx={{
						mx: 'auto',
						mt: 8,
						p: 2,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						borderRadius: 2,
						border: 1,
						borderColor: 'divider',
					}}
				>
					<Avatar
						sx={{
							mt: 4,
							mb: 2,
							backgroundColor: 'secondary.main',
						}}
					>
						<LockOutlinedIcon />
					</Avatar>

					<Typography component="h1" variant="h5">
						Zadání nového hesla
					</Typography>

					<Formik
						initialValues={{
							newPassword: '',
							newPassword2: '',
						}}
						onSubmit={async (values, { resetForm }) => {
							setErrorMessage(null);
							const response = await post('/api/users/new-password', {
								...values,
								code,
							});

							if (response.status === 204) {
								resetForm();
								navigate('/');
							} else {
								console.log({ response });
								setErrorMessage(response.data.error);
							}
						}}
					>
						{({ values, isSubmitting, handleChange, handleBlur, handleSubmit }) => {
							return (
								<form onSubmit={handleSubmit}>
									<FormControl
										variant="standard"
										fullWidth
										sx={{
											mt: 6,
											mb: 1,
										}}
									>
										<InputLabel htmlFor="newPassword">Nové heslo</InputLabel>
										<Input
											id="newPassword"
											value={values.newPassword}
											onChange={handleChange}
											type={showPassword ? 'text' : 'password'}
											autoComplete="new-password"
											endAdornment={
												<InputAdornment position="end">
													<IconButton
														aria-label="Přepnout viditelnost hesla"
														onClick={handleClickShowPassword}
														onMouseDown={handleMouseDownPassword}
														size="large"
													>
														{showPassword ? (
															<VisibilityIcon />
														) : (
															<VisibilityOffIcon />
														)}
													</IconButton>
												</InputAdornment>
											}
										/>
									</FormControl>
									<TextField
										id="newPassword2"
										label="Nové heslo (znova)"
										value={values.newPassword2}
										onChange={handleChange}
										type={showPassword ? 'text' : 'password'}
										margin="normal"
										fullWidth
										autoComplete="new-password"
										variant="standard"
									/>

									{errorMessage && <Alert severity="error">{errorMessage}</Alert>}

									<Box
										sx={{
											mt: 7,
											mb: 3,
											display: 'flex',
											justifyContent: 'flex-end',
										}}
									>
										<Button
											type="submit"
											disabled={isSubmitting}
											color="primary"
											startIcon={<SaveIcon />}
											variant="contained"
										>
											Uložit
										</Button>
									</Box>
								</form>
							);
						}}
					</Formik>
				</Box>
			</Container>
		</>
	);
};

export default NewPasswordPage;
