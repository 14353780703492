import { FC } from 'react';
import { Formik } from 'formik';
import useSWR, { useSWRConfig } from 'swr';

import { Save as SaveIcon } from '@mui/icons-material';
import {
	Box,
	Button,
	Checkbox,
	Container,
	FormControlLabel,
	Paper,
	TextField,
} from '@mui/material';

import put from '../../libs/put';
import { IEmailTemplate } from '../../types';
import showMessage from '../../utils/showMessage';

const EmailTemplates: FC = () => {
	const { data } = useSWR<IEmailTemplate>('/api/templates/email');
	const { mutate } = useSWRConfig();

	if (!data) {
		return null;
	}

	return (
		<Formik
			initialValues={{
				...data,
			}}
			onSubmit={async (values) => {
				try {
					const response = await put(`/api/templates/email`, values);
					if (response.status === 204) {
						mutate('/api/templates/email');
						showMessage({
							msg: `Nastavení e-mailových šablon bylo uloženo.`,
						});
					}
				} catch (e) {
					console.log(e);
					showMessage({
						msg: `Chyba při ukládání.`,
						severity: 'error',
						duration: 60000,
					});
				}
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => {
				return (
					<form onSubmit={handleSubmit}>
						<Container maxWidth="md">
							<Box py={1} mb={2}>
								<Box m={1} mt={3}>
									<Paper elevation={3}>
										<Box m={3} p={2}>
											<h3>
												Upozornění na novou registraci - e-mail pro
												administrátora
											</h3>
											<FormControlLabel
												control={
													<Checkbox
														id="adminNewRegistrationEnabled"
														checked={values.adminNewRegistrationEnabled}
														onChange={handleChange}
														color="primary"
													/>
												}
												label="Zapnout"
											/>
											<Box mt={2} mb={3}>
												<TextField
													id="adminNewRegistrationEmail"
													value={values.adminNewRegistrationEmail}
													onChange={handleChange}
													label="Komu"
													variant="outlined"
													fullWidth
												/>
											</Box>
											<Box mb={3}>
												<TextField
													id="adminNewRegistrationSubject"
													value={values.adminNewRegistrationSubject}
													onChange={handleChange}
													label="Předmět e-mailu"
													variant="outlined"
													fullWidth
												/>
											</Box>
											<Box mb={3}>
												<TextField
													id="adminNewRegistrationBody"
													value={values.adminNewRegistrationBody}
													onChange={handleChange}
													label="Obsah e-mailu"
													variant="outlined"
													multiline
													minRows={10}
													maxRows={20}
													fullWidth
												/>
											</Box>
										</Box>
									</Paper>

									<Paper elevation={3}>
										<Box m={3} p={2}>
											<h3>
												Potvrzení, že přihláška byla uložena - e-mail pro
												uživatele
											</h3>
											<FormControlLabel
												control={
													<Checkbox
														id="userRegistrationCreatedEnabled"
														checked={
															values.userRegistrationCreatedEnabled
														}
														onChange={handleChange}
														color="primary"
													/>
												}
												label="Zapnout"
											/>
											<Box mt={3} mb={3}>
												<TextField
													value="uzivateluv.email@zadany-na-prihlasce.cz"
													disabled
													label="Komu"
													variant="outlined"
													fullWidth
												/>
											</Box>
											<Box mb={3}>
												<TextField
													id="userRegistrationCreatedSubject"
													value={values.userRegistrationCreatedSubject}
													onChange={handleChange}
													label="Předmět e-mailu"
													variant="outlined"
													fullWidth
												/>
											</Box>
											<Box mb={3}>
												<TextField
													id="userRegistrationCreatedBody"
													value={values.userRegistrationCreatedBody}
													onChange={handleChange}
													label="Obsah e-mailu"
													variant="outlined"
													multiline
													minRows={10}
													maxRows={20}
													fullWidth
												/>
											</Box>
										</Box>
									</Paper>

									<Paper elevation={3}>
										<Box m={3} p={2}>
											<h3>
												Potvrzení, že přihláška byla schválena - e-mail pro
												uživatele
											</h3>
											<FormControlLabel
												control={
													<Checkbox
														id="userRegistrationAcceptedEnabled"
														checked={
															values.userRegistrationAcceptedEnabled
														}
														onChange={handleChange}
														color="primary"
													/>
												}
												label="Zapnout"
											/>
											<Box mt={3} mb={3}>
												<TextField
													value="uzivateluv.email@zadany-na-prihlasce.cz"
													disabled
													label="Komu"
													variant="outlined"
													fullWidth
												/>
											</Box>
											<Box mb={3}>
												<TextField
													id="userRegistrationAcceptedSubject"
													value={values.userRegistrationAcceptedSubject}
													onChange={handleChange}
													label="Předmět e-mailu"
													variant="outlined"
													fullWidth
												/>
											</Box>
											<Box mb={3}>
												<TextField
													id="userRegistrationAcceptedBody"
													value={values.userRegistrationAcceptedBody}
													onChange={handleChange}
													label="Obsah e-mailu"
													variant="outlined"
													multiline
													minRows={10}
													maxRows={20}
													fullWidth
												/>
											</Box>
										</Box>
									</Paper>
								</Box>
								<Box m={1} mb={1} display="flex" justifyContent="flex-end">
									<Button
										type="submit"
										disabled={isSubmitting}
										color="primary"
										startIcon={<SaveIcon />}
										variant="contained"
									>
										Uložit
									</Button>
								</Box>
							</Box>
						</Container>
					</form>
				);
			}}
		</Formik>
	);
};

export default EmailTemplates;
