import { useState, FC, ReactElement } from 'react';

import { Alert, AlertColor, Snackbar, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

interface SlideTransitionProps extends TransitionProps {
	children: ReactElement<any, any>;
}

const SlideTransition: FC<SlideTransitionProps> = (props) => {
	return <Slide {...props} direction="left" />;
};

interface Props {
	msg: string;
	duration: number;
	severity?: AlertColor;
}

const Message: React.FC<Props> = ({ msg, duration = 10000, severity }) => {
	const [open, setOpen] = useState(true);

	const handleClose = (_event?: React.SyntheticEvent<any> | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	return (
		<Snackbar
			open={open}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			autoHideDuration={duration}
			onClose={handleClose}
			TransitionComponent={SlideTransition}
		>
			<Alert elevation={6} variant="filled" onClose={handleClose} severity={severity}>
				{msg}
			</Alert>
		</Snackbar>
	);
};

export default Message;
