import { FC } from 'react';

import ShowDocxTemplates from './ShowDocxTemplates';
import EvaluationDocxTemplates from './EvaluationDocxTemplates';
import { useStatus } from '../../components/StatusContext';

const DocxTemplates: FC = () => {
	const { isBonitace } = useStatus();

	return isBonitace ? <EvaluationDocxTemplates /> : <ShowDocxTemplates />;
};

export default DocxTemplates;
