import { FC, useState } from 'react';
import { Formik } from 'formik';
import { useSWRConfig } from 'swr';
import { Link } from 'react-router-dom';

import {
	Close as CloseIcon,
	DeleteOutlined as DeleteOutlinedIcon,
	Pets as PetsIcon,
	Save as SaveIcon,
} from '@mui/icons-material';
import {
	Button,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	Grid,
	Icon,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { Dog } from '../../types';
import { trida, pohlavi, srst, bonitace } from '../../utils';
import put from '../../libs/put';
import showMessage from '../../utils/showMessage';
import { hodnoceni } from '../../utils/hodnoceni';
import remove from '../../libs/remove';
import { menu } from '../../menu';
import { useStatus } from '../../components/StatusContext';

const StyledTextField = styled(TextField)({
	marginLeft: 1,
	marginRight: 1,
});

const StyledFormControl = styled(FormControl)(({ theme }) => ({
	margin: theme.spacing(2, 1),
	minWidth: 120,
}));

const Section = styled(Container)(({ theme }) => ({
	padding: theme.spacing(1),
	textAlign: 'center',
}));

interface IShowFormProps {
	data: Dog;
	onClose: () => void;
}

const ShowForm: FC<IShowFormProps> = ({ data, onClose }) => {
	const { mutate } = useSWRConfig();
	const { isBonitace } = useStatus();
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

	const handleDeleteClick = async () => {
		try {
			const response = await remove(`/api/dogs/${data.id}`);
			if (response.status === 204) {
				mutate('/api/dogs/event');

				showMessage({
					msg: `Pes byl smazán.`,
				});

				onClose();
			}
		} catch (e) {
			showMessage({
				msg: `Chyba při mazání psa.`,
				severity: 'error',
				duration: 60000,
			});
		}
	};

	return (
		<>
			<Formik
				initialValues={data}
				onSubmit={async (values) => {
					try {
						const response = await put(`/api/dogs/${data.id}`, values);
						if (response.status === 200) {
							mutate('/api/dogs/event');

							showMessage({
								msg: `Pes ${values.jmeno} uložen.`,
							});

							onClose();
						} else {
							throw new Error();
						}
					} catch (e) {
						showMessage({
							msg: `Chyba při ukládání psa.`,
							severity: 'error',
							duration: 60000,
						});
					}
				}}
			>
				{({
					values,
					touched,
					errors,
					dirty,
					isSubmitting,
					handleChange,
					handleBlur,
					handleSubmit,
					handleReset,
				}) => {
					return (
						<form onSubmit={handleSubmit}>
							<Typography
								variant="h4"
								component="h3"
								sx={(theme) => ({
									borderBottom: `4px solid ${theme.palette.primary.main}`,
									paddingBottom: theme.spacing(1),
									margin: theme.spacing(4, 4, 1, 4),
									display: 'flex',
									justifyContent: 'space-between',
								})}
							>
								Detail psa
								<IconButton
									color="primary"
									aria-label="Zavřít"
									title="Zavřít"
									component="span"
									onClick={onClose}
									size="large"
								>
									<CloseIcon />
								</IconButton>
							</Typography>

							<Container
								sx={(theme) => ({
									overflowY: 'auto',
									paddingLeft: 0,
									height: 'calc(100vh - 170px)',
									[theme.breakpoints.down('sm')]: {
										paddingLeft: theme.spacing(2),
										height: 'calc(100vh - 280px)',
									},
								})}
							>
								<Grid container>
									<Grid item xs={12} sm={2}>
										<Section>
											<Icon
												className="fa fa-dog"
												fontSize="large"
												style={{
													transform: 'scaleX(-1)',
													width: '1.2em',
												}}
											/>
											<Typography>Pes</Typography>
										</Section>
									</Grid>
									<Grid item container sm={10} spacing={2}>
										<Grid item xs={12} sm={6} md={3}>
											<StyledTextField
												id="cisloVKatalogu"
												label="Číslo v katalogu"
												value={values.cisloVKatalogu}
												onChange={handleChange}
												margin="normal"
												variant="standard"
												fullWidth
											/>
										</Grid>
										<Grid item xs={12} sm={6} md={3}>
											<StyledFormControl variant="standard" fullWidth>
												<InputLabel id="pohlavi-label">Pohlaví</InputLabel>
												<Select
													id="pohlavi"
													name="pohlavi"
													labelId="pohlavi-label"
													value={values.pohlavi}
													onChange={handleChange}
													variant="standard"
													fullWidth
												>
													{pohlavi.map((option) => (
														<MenuItem
															key={option.value}
															value={option.value}
														>
															{option.label}
														</MenuItem>
													))}
												</Select>
											</StyledFormControl>
										</Grid>

										<Grid item xs={12} sm={6} md={3}>
											{!isBonitace && (
												<StyledFormControl variant="standard" fullWidth>
													<InputLabel id="trida-label">Třída</InputLabel>
													<Select
														id="trida"
														name="trida"
														labelId="trida-label"
														value={values.trida}
														onChange={handleChange}
														variant="standard"
													>
														{trida.map((option) => (
															<MenuItem
																key={option.value}
																value={option.value}
															>
																{option.label}
															</MenuItem>
														))}
													</Select>
												</StyledFormControl>
											)}
										</Grid>
										<Grid item xs={12} sm={6} md={3}>
											{!isBonitace && (
												<StyledFormControl variant="standard" fullWidth>
													<InputLabel id="srst-label">Srst</InputLabel>
													<Select
														id="srst"
														name="srst"
														labelId="srst-label"
														value={values.srst}
														onChange={handleChange}
														variant="standard"
													>
														{srst.map((option) => (
															<MenuItem
																key={option.value}
																value={option.value}
															>
																{option.label}
															</MenuItem>
														))}
													</Select>
												</StyledFormControl>
											)}

											{isBonitace && (
												<StyledFormControl variant="standard" fullWidth>
													<InputLabel id="typBonitace-label">
														Typ bonitace
													</InputLabel>
													<Select
														id="typBonitace"
														name="typBonitace"
														labelId="typBonitace-label"
														value={values.typBonitace}
														onChange={handleChange}
														variant="standard"
													>
														{bonitace.map((option) => (
															<MenuItem
																key={option.value}
																value={option.value}
															>
																{option.label}
															</MenuItem>
														))}
													</Select>
												</StyledFormControl>
											)}
										</Grid>

										<Grid item xs={12} md={6}>
											<StyledTextField
												id="jmeno"
												label="Jméno"
												value={values.jmeno}
												onChange={handleChange}
												margin="normal"
												variant="standard"
												fullWidth
											/>
										</Grid>

										<Grid item xs={12} sm={6}>
											<StyledTextField
												id="datumNarozeni"
												label="Datum narození"
												value={values.datumNarozeni}
												onChange={handleChange}
												margin="normal"
												variant="standard"
												fullWidth
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<StyledTextField
												id="cisloZapisu"
												label="Číslo zápisu"
												value={values.cisloZapisu}
												onChange={handleChange}
												margin="normal"
												variant="standard"
												fullWidth
											/>
										</Grid>

										<Grid item xs={12} sm={6}>
											<StyledTextField
												id="rentgen"
												label="Rentgen"
												value={values.rentgen}
												onChange={handleChange}
												margin="normal"
												variant="standard"
												fullWidth
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<StyledTextField
												id="cisloCipu"
												label="Číslo čipu"
												value={values.cisloCipu}
												onChange={handleChange}
												margin="normal"
												variant="standard"
												fullWidth
											/>
										</Grid>

										<Grid item xs={12} sm={12} md={6}>
											<StyledTextField
												id="zkousky"
												label="Zkoušky"
												value={values.zkousky}
												onChange={handleChange}
												margin="normal"
												variant="standard"
												fullWidth
											/>
										</Grid>

										{isBonitace && (
											<Grid item xs={12} sm={12} md={12}>
												<StyledTextField
													id="oceneni"
													label="Výstavní ocenění"
													value={values.oceneni}
													onChange={handleChange}
													margin="normal"
													variant="standard"
													fullWidth
												/>
											</Grid>
										)}
									</Grid>
								</Grid>

								<Grid container>
									<Grid item xs={12} sm={2}>
										<Section>
											<Icon
												className="fa fa-venus-mars"
												fontSize="large"
												style={{ width: '1.2em' }}
											/>
											<Typography>Rodiče</Typography>
										</Section>
									</Grid>
									<Grid item container sm={10} spacing={2}>
										<Grid item xs={12} md={6}>
											<StyledTextField
												id="otecJmeno"
												label="Otec - jméno"
												value={values.otecJmeno}
												onChange={handleChange}
												margin="normal"
												variant="standard"
												fullWidth
											/>
										</Grid>
										{isBonitace && (
											<Grid item xs={12} md={6}>
												<StyledTextField
													id="otecCisloZapisu"
													label="Otec - číslo zápisu"
													value={values.otecCisloZapisu}
													onChange={handleChange}
													margin="normal"
													variant="standard"
													fullWidth
												/>
											</Grid>
										)}
										<Grid item xs={12} md={6}>
											<StyledTextField
												id="matkaJmeno"
												label="Matka - jméno"
												value={values.matkaJmeno}
												onChange={handleChange}
												margin="normal"
												variant="standard"
												fullWidth
											/>
										</Grid>
										{isBonitace && (
											<Grid item xs={12} md={6}>
												<StyledTextField
													id="matkaCisloZapisu"
													label="Matka - číslo zápisu"
													value={values.matkaCisloZapisu}
													onChange={handleChange}
													margin="normal"
													variant="standard"
													fullWidth
												/>
											</Grid>
										)}
									</Grid>
								</Grid>

								<Grid container>
									<Grid item xs={12} sm={2}>
										<Section>
											<Icon
												className="fa fa-user"
												fontSize="large"
												style={{ width: '1.2em' }}
											/>
											<Typography>Kontakt</Typography>
										</Section>
									</Grid>
									<Grid item container sm={10} spacing={2}>
										<Grid item xs={12} md={6}>
											<StyledTextField
												id="chovatelJmeno"
												label="Chovatel - jméno"
												value={values.chovatelJmeno}
												onChange={handleChange}
												margin="normal"
												variant="standard"
												fullWidth
											/>
										</Grid>
										<Grid item xs={12} md={6}>
											<StyledTextField
												id="chovatelAdresa"
												label="Chovatel - adresa"
												value={values.chovatelAdresa}
												onChange={handleChange}
												margin="normal"
												variant="standard"
												fullWidth
											/>
										</Grid>
										<Grid item xs={12} md={6}>
											<StyledTextField
												id="majitelJmeno"
												label="Majitel - jméno"
												value={values.majitelJmeno}
												onChange={handleChange}
												margin="normal"
												variant="standard"
												fullWidth
											/>
										</Grid>
										<Grid item xs={12} md={6}>
											<StyledTextField
												id="majitelAdresa"
												label="Majitel - adresa"
												value={values.majitelAdresa}
												onChange={handleChange}
												margin="normal"
												variant="standard"
												fullWidth
											/>
										</Grid>
										<Grid item xs={12} md={6}>
											<StyledTextField
												id="telefon"
												label="Telefon"
												value={values.telefon}
												onChange={handleChange}
												margin="normal"
												variant="standard"
												fullWidth
											/>
										</Grid>
										<Grid item xs={12} md={6}>
											<TextField
												id="email"
												label="E-mail"
												value={values.email}
												onChange={handleChange}
												margin="normal"
												variant="standard"
												fullWidth
											/>
										</Grid>
									</Grid>
								</Grid>

								<Grid container>
									<Grid item xs={12} sm={2}>
										<Section>
											<Icon
												className="fa fa-poll"
												fontSize="large"
												style={{ width: '1.2em' }}
											/>
											<Typography>Výsledky</Typography>
										</Section>
									</Grid>
									<Grid item container sm={10} spacing={2}>
										<Grid item xs={12} sm={6}>
											<StyledFormControl variant="standard" fullWidth>
												<InputLabel id="hodnoceni-label">
													Hodnocení
												</InputLabel>
												<Select
													id="hodnoceni"
													name="hodnoceni"
													labelId="hodnoceni-label"
													value={values.hodnoceni ?? ''}
													onChange={handleChange}
													variant="standard"
												>
													{hodnoceni.map((option) => (
														<MenuItem
															key={option.value}
															value={option.value}
														>
															{option.label}
														</MenuItem>
													))}
												</Select>
											</StyledFormControl>
										</Grid>
										<Grid item xs={12} sm={6}>
											<StyledTextField
												id="poradi"
												label="Pořadí"
												value={values.poradi || ''}
												onChange={handleChange}
												margin="normal"
												variant="standard"
												fullWidth
											/>
										</Grid>
									</Grid>
								</Grid>
							</Container>

							<Container sx={{ padding: (theme) => theme.spacing(1, 4) }}>
								<Grid container spacing={1}>
									<Grid container item spacing={1} xs={12} sm={6}>
										<Grid item xs={12} sm={6} md={4}>
											<Button
												type="button"
												variant="outlined"
												startIcon={<DeleteOutlinedIcon />}
												onClick={() => setDeleteDialogOpen(true)}
												fullWidth
											>
												Smazat
											</Button>
										</Grid>
										{data?.registration?.id && (
											<Grid item xs={12} sm={6} md={4}>
												<Button
													type="button"
													variant="outlined"
													startIcon={<PetsIcon />}
													component={Link}
													to={`${menu.registrations.path}/${data?.registration?.id}`}
													fullWidth
												>
													Přihláška
												</Button>
											</Grid>
										)}
									</Grid>
									<Grid
										container
										item
										spacing={1}
										xs={12}
										sm={6}
										justifyContent="flex-end"
									>
										<Grid item xs={12} sm={6} md={4}>
											<Button
												type="submit"
												disabled={isSubmitting}
												variant="contained"
												color="primary"
												startIcon={<SaveIcon />}
												fullWidth
												sx={{
													width: '100%',
												}}
											>
												Uložit
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</Container>
						</form>
					);
				}}
			</Formik>

			<Dialog
				open={deleteDialogOpen}
				onClose={onClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">Chcete smazat psa z výstavy?</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						<strong>Upozornění:</strong> Majitel NEBUDE o smazání psa informován
						e-mailem.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDeleteClick} color="primary" autoFocus>
						OK
					</Button>
					<Button onClick={() => setDeleteDialogOpen(false)} color="secondary">
						Nechci
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default ShowForm;
