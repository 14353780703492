export default async function post<T = any>(
	input: RequestInfo,
	data?: {},
): Promise<{
	ok: boolean;
	data?: T;
	text?: string;
	status: number;
	statusText: string;
}> {
	const response = await fetch(input, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(data),
	});

	let responseData;
	let responseText;
	if (response.headers.get('content-type')?.split(';')[0] === 'application/json') {
		responseData = await response.json();
	} else {
		responseText = await response.text();
	}

	return {
		ok: response.ok,
		data: responseData,
		text: responseText,
		status: response.status,
		statusText: response.statusText,
	};
}
