import { FC } from 'react';

import { Box, CircularProgress, Typography } from '@mui/material';

const Loading: FC = () => {
	return (
		<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<CircularProgress
				disableShrink
				sx={{
					margin: (theme) => theme.spacing(2),
				}}
			/>

			<Typography component="h2">Nahrávají se data ...</Typography>
		</Box>
	);
};

export default Loading;
