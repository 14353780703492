export const pohlavi = [
	{
		value: 'F',
		label: 'Fena',
		label2: 'Feny',
	},
	{
		value: 'P',
		label: 'Pes',
		label2: 'Psi',
	},
];

export const convertPohlavi = (input?: string): string => {
	switch (input) {
		case 'F':
			return 'Fena';
		case 'P':
			return 'Pes';
		default:
			console.warn('Neznámý kód pohlaví:', input);
			return '?';
	}
};

export const convertPohlavi2 = (input?: string): string => {
	switch (input) {
		case 'F':
			return 'feny';
		case 'P':
			return 'psi';
		default:
			console.warn('Neznámý kód pohlaví:', input);
			return '?';
	}
};

export const srst = [
	{
		value: 'K',
		label: 'Krátká',
	},
	{
		value: 'D',
		label: 'Dlouhá',
	},
];

export const convertSrst = (input?: string): string => {
	switch (input) {
		case 'K':
			return 'Krátká';
		case 'D':
			return 'Dlouhá';
		default:
			console.warn('Neznámý kód srsti:', input);
			return '?';
	}
};

export const convertTrida = (input?: string | number): string => {
	switch (input) {
		case '1':
		case 1:
			return 'Dorost';
		case '2':
		case 2:
			return 'Mladých';
		case '3':
		case 3:
			return 'Dospívajících';
		case '4':
		case 4:
			return 'Pracovní';
		case '5':
		case 5:
			return 'Veteráni';
		default:
			console.warn('Neznámý kód třídy:', input);
			return '?';
	}
};

export const convertTrida2 = (input?: string | number): string => {
	switch (input) {
		case '1':
		case 1:
			return 'dorostu';
		case '2':
		case 2:
			return 'mladých';
		case '3':
		case 3:
			return 'dospívajících';
		case '4':
		case 4:
			return 'pracovní';
		case '5':
		case 5:
			return 'veteráni';
		default:
			console.warn('Neznámý kód třídy:', input);
			return '?';
	}
};

export const trida = [
	{
		value: '1',
		label: 'Dorost',
	},
	{
		value: '2',
		label: 'Mladých',
	},
	{
		value: '3',
		label: 'Dospívajících',
	},
	{
		value: '4',
		label: 'Pracovní',
	},
	{
		value: '5',
		label: 'Veteráni',
	},
];

export function convertHodnoceni(input: number) {
	if (input === 1) return 'V';
	if (input === 2) return 'VD';
	if (input === 3) return 'D';
	if (input === 4) return 'dostatečný';
	if (input === 5) return 'nedostatečný';
	if (input === 6) return 'VN';
	if (input === 7) return 'N';
	if (input === 8) return 'nenast.';
	if (input === 9) return 'odst.';
	return '(neznámé hodnocení)';
}

export function convertPoradi(trida: number, hodnoceni: number, poradi: number) {
	/*
    Třída dorostu :
        Velmi nadějný - VN (to je nejlepší hodnocení) vždy s pořadím
        Nadějný - N (horší hodnocení)

    Třída mladých :
        Velmi dobrý - VD (lepší) vždy s pořadím
        Dobrý - D (horší)

    Třída dospívajících :
        Velmi dobrý - VD vždy s pořadím
        Dobrý - D

    Třída pracovní :
        Výborný - V vždy s pořadím
        Velmi dobrý - VD
        Dobrý - D
    */

	if (poradi > 0) {
		// trida dorost
		if (trida === 1 && hodnoceni === 6) return poradi;

		// trida mladych
		if (trida === 2 && hodnoceni === 2) return poradi;

		// trida dospivajicich
		if (trida === 3 && hodnoceni === 2) return poradi;

		// trida pracovni
		if (trida === 4 && hodnoceni === 1) return poradi;

		// trida veterani
		if (trida === 5 && hodnoceni === 1) return poradi;
	}
	return '';
}

export const bonitace = [
	{
		value: 'B',
		label: 'Bonitace',
	},
	{
		value: 'P',
		label: 'Přebonitace',
	},
];

export const convertTypBonitace = (input?: string): string => {
	switch (input) {
		case 'B':
			return 'Bonitace';
		case 'P':
			return 'Přebonitace';
		default:
			console.warn('Neznámý kód typu bonitace:', input);
			return '?';
	}
};
