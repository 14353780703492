import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { SWRConfig } from 'swr';

import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';

import App from './App';
import fetch from './libs/fetch';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

const theme = createTheme({
	palette: {
		background: {
			default: '#fafafa',
		},
	},

	components: {
		MuiDrawer: {
			styleOverrides: {
				root: {
					zIndex: 1205,
				},
				paper: {
					borderRadius: '10px 0 0 10px',
				},
			},
		},
	},
});

createRoot(document.getElementById('root') as HTMLElement).render(
	<StrictMode>
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<BrowserRouter>
					<SWRConfig
						value={{
							refreshInterval: 10000,
							fetcher: fetch,
						}}
					>
						<App />
					</SWRConfig>
				</BrowserRouter>
			</ThemeProvider>
		</StyledEngineProvider>
	</StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://create-react-app.dev/docs/making-a-progressive-web-app/
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://create-react-app.dev/docs/measuring-performance/
reportWebVitals();
