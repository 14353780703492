import { FC } from 'react';
import useSWR from 'swr';
import { Box, Container, List, Typography } from '@mui/material';

import { IJudge } from '../../../types';
import JudgesDogAssignPanel from './JudgesDogAssignPanel';
import NewJudge from './NewJudge';
import EditJudge from './EditJudge';
import { useStatus } from '../../../components/StatusContext';
import JudgesEvaluationAssignPanel from './JudgesEvaluationAssignPanel';

const Judges: FC = () => {
	const { isBonitace } = useStatus();
	const { data } = useSWR<IJudge[]>('/api/settings/judges');

	if (!data) {
		return null;
	}

	return (
		<>
			<Container maxWidth="sm">
				<Box sx={{ py: 2 }}>
					<Typography variant="h6">Seznam rozhodčích</Typography>
				</Box>

				<List>
					{data.map((judge) => (
						<EditJudge judge={judge} key={judge.id} />
					))}
				</List>

				<NewJudge />
			</Container>

			<Container maxWidth="md">
				<Box sx={{ py: 2 }}>
					<Typography variant="h6">Přiřazení rozhodčího</Typography>
				</Box>

				{isBonitace ? (
					<JudgesEvaluationAssignPanel judges={data} />
				) : (
					<JudgesDogAssignPanel judges={data} />
				)}
			</Container>
		</>
	);
};

export default Judges;
