import { Switch } from '@mui/material';
import { styled } from '@mui/material/styles';
import { green, red } from '@mui/material/colors';

const GreenSwitch = styled(Switch)({
	switchBase: {
		color: red[500],
		'&$checked': {
			color: green[500],
		},
		'&$checked + $track': {
			backgroundColor: green[500],
		},
	},
	checked: {},
	track: {
		backgroundColor: red[500],
	},
});

export default GreenSwitch;
