import { FC } from 'react';
import { Link } from 'react-router-dom';

import { ChevronLeft as ChevronLeftIcon } from '@mui/icons-material';
import { Divider, Drawer as MuiDrawer, IconButton, List, Typography } from '@mui/material';
import { styled, Theme, CSSObject } from '@mui/material/styles';

import { useStatus } from './StatusContext';
import { menu } from '../menu';
import MenuItem from './MenuItem';

export const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
	borderRadius: 0,
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(9)} + 1px)`,
	},
	borderRadius: 0,
});

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 1),
	backgroundColor: theme.palette.primary.dark,
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
	({ theme, open }) => ({
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		boxSizing: 'border-box',
		...(open && {
			...openedMixin(theme),
			'& .MuiDrawer-paper': openedMixin(theme),
		}),
		...(!open && {
			...closedMixin(theme),
			'& .MuiDrawer-paper': closedMixin(theme),
		}),
		zIndex: 1200,
	}),
);

interface IMenuProps {
	isOpen: boolean;
	onHide: () => void;
}

const Menu: FC<IMenuProps> = ({ isOpen, onHide }) => {
	const { isBonitace } = useStatus();

	return (
		<Drawer variant="permanent" open={isOpen}>
			<DrawerHeader>
				<Typography
					component={Link}
					to="/"
					variant="h6"
					color="inherit"
					noWrap
					sx={{
						flex: '1 1 auto',
						color: (theme) => theme.palette.common.white,
						textDecoration: 'none',
					}}
				>
					Výstava psů
				</Typography>

				<IconButton
					onClick={onHide}
					title="Skrýt menu"
					size="large"
					sx={{
						color: (theme) => theme.palette.primary.light,
					}}
				>
					<ChevronLeftIcon />
				</IconButton>
			</DrawerHeader>

			<Divider />

			<List>
				{Object.values(menu).map((menuItem) => {
					const hideIfBonitace = isBonitace && menuItem.hideBonitace;

					if (!menuItem.skipMenu && !hideIfBonitace) {
						return (
							<MenuItem key={menuItem.path} isMenuOpen={isOpen} menuItem={menuItem} />
						);
					}
					return null;
				})}
			</List>
		</Drawer>
	);
};

export default Menu;
