import { FC } from 'react';
import { Formik } from 'formik';
import { useSWRConfig } from 'swr';

import { Close as CloseIcon, Save as SaveIcon } from '@mui/icons-material';
import {
	Button,
	Container,
	FormControl,
	Grid,
	Icon,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
	styled,
	StandardTextFieldProps,
} from '@mui/material';

import { trida, pohlavi, srst, bonitace } from '../../utils';
import post from '../../libs/post';
import showMessage from '../../utils/showMessage';
import { getCurrentDate } from '../../utils/date';
import { useStatus } from '../../components/StatusContext';

const SectionContainer = styled(Container)(({ theme }) => ({
	padding: theme.spacing(2),
	textAlign: 'center',
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
	margin: theme.spacing(2, 1),
	minWidth: 120,
}));

const StyledTextField = styled(TextField)<StandardTextFieldProps>(({ theme }) => ({
	marginLeft: theme.spacing(1),
	marginRight: theme.spacing(1),
}));

interface Props {
	onClose: () => void;
}

const NewRegistrationForm: FC<Props> = ({ onClose }) => {
	const { isBonitace } = useStatus();
	const { mutate } = useSWRConfig();

	return (
		<Formik
			initialValues={{
				typBonitace: '',
				jmeno: '',
				pohlavi: '',
				cisloZapisu: '',
				zkousky: '',
				oceneni: '',
				datumNarozeni: '',
				rentgen: '',
				cisloCipu: '',
				trida: '',
				srst: '',
				otecJmeno: '',
				otecCisloZapisu: '',
				matkaJmeno: '',
				matkaCisloZapisu: '',
				chovatelJmeno: '',
				chovatelAdresa: '',
				majitelJmeno: '',
				majitelAdresa: '',
				email: '',
				telefon: '',
				vyrizena: false,
				zprava: `Ručně vyplněná přihláška dne ${getCurrentDate()}`,
			}}
			onSubmit={async (values) => {
				try {
					const response = await post(`/api/registrations`, values);
					if (response.status === 200) {
						mutate('/api/registrations');

						showMessage({
							msg: `Pes ${values.jmeno} zaregistrován.`,
						});

						onClose();
					}
				} catch (e) {
					showMessage({
						msg: `Chyba při ukládání přihlášky.`,
						severity: 'error',
						duration: 60000,
					});
				}
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => {
				return (
					<form onSubmit={handleSubmit}>
						<Typography
							variant="h4"
							component="h3"
							sx={{
								borderBottom: (theme) => `4px solid ${theme.palette.primary.main}`,
								paddingBottom: (theme) => theme.spacing(1),
								margin: (theme) => theme.spacing(4, 4, 1, 4),
								display: 'flex',
								justifyContent: 'space-between',
							}}
						>
							Nová přihláška
							<IconButton
								color="primary"
								aria-label="Zavřít"
								title="Zavřít"
								component="span"
								onClick={onClose}
								size="large"
							>
								<CloseIcon />
							</IconButton>
						</Typography>

						<Container
							sx={{
								overflowY: 'auto',
								// https://mui.com/system/basics/#responsive-values
								height: {
									xs: 'calc(100vh - 200px)',
									md: 'calc(100vh - 160px)',
								},
								paddingLeft: {
									xs: 16,
									md: 0,
								},
							}}
						>
							<Grid container>
								<Grid item xs={12} sm={2}>
									<SectionContainer>
										<Icon
											className="fa fa-dog"
											fontSize="large"
											style={{
												transform: 'scaleX(-1)',
												width: '1.2em',
											}}
										/>
										<Typography>Pes</Typography>
									</SectionContainer>
								</Grid>
								<Grid item container sm={10} spacing={2}>
									<Grid item xs={12} sm={6} md={3}></Grid>
									<Grid item xs={12} sm={6} md={3}>
										<StyledFormControl variant="standard" fullWidth>
											<InputLabel id="pohlavi-label">Pohlaví</InputLabel>
											<Select
												id="pohlavi"
												name="pohlavi"
												labelId="pohlavi-label"
												value={values.pohlavi}
												onChange={handleChange}
												variant="standard"
												fullWidth
											>
												{pohlavi.map((option) => (
													<MenuItem
														key={option.value}
														value={option.value}
													>
														{option.label}
													</MenuItem>
												))}
											</Select>
										</StyledFormControl>
									</Grid>

									<Grid item xs={12} sm={6} md={3}>
										{!isBonitace && (
											<StyledFormControl variant="standard" fullWidth>
												<InputLabel id="trida-label">Třída</InputLabel>
												<Select
													id="trida"
													name="trida"
													labelId="trida-label"
													value={values.trida}
													onChange={handleChange}
													variant="standard"
												>
													{trida.map((option) => (
														<MenuItem
															key={option.value}
															value={option.value}
														>
															{option.label}
														</MenuItem>
													))}
												</Select>
											</StyledFormControl>
										)}
									</Grid>
									<Grid item xs={12} sm={6} md={3}>
										{!isBonitace && (
											<StyledFormControl variant="standard" fullWidth>
												<InputLabel id="srst-label">Srst</InputLabel>
												<Select
													id="srst"
													name="srst"
													labelId="srst-label"
													value={values.srst}
													onChange={handleChange}
													variant="standard"
												>
													{srst.map((option) => (
														<MenuItem
															key={option.value}
															value={option.value}
														>
															{option.label}
														</MenuItem>
													))}
												</Select>
											</StyledFormControl>
										)}

										{isBonitace && (
											<StyledFormControl variant="standard" fullWidth>
												<InputLabel id="typBonitace-label">
													Typ bonitace
												</InputLabel>
												<Select
													id="typBonitace"
													name="typBonitace"
													labelId="typBonitace-label"
													value={values.typBonitace}
													onChange={handleChange}
													variant="standard"
												>
													{bonitace.map((option) => (
														<MenuItem
															key={option.value}
															value={option.value}
														>
															{option.label}
														</MenuItem>
													))}
												</Select>
											</StyledFormControl>
										)}
									</Grid>

									<Grid item xs={12} md={6}>
										<StyledTextField
											id="jmeno"
											label="Jméno"
											value={values.jmeno}
											onChange={handleChange}
											margin="normal"
											variant="standard"
											fullWidth
										/>
									</Grid>

									<Grid item xs={12} sm={6}>
										<StyledTextField
											id="datumNarozeni"
											label="Datum narození"
											value={values.datumNarozeni}
											onChange={handleChange}
											margin="normal"
											variant="standard"
											fullWidth
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<StyledTextField
											id="cisloZapisu"
											label="Číslo zápisu"
											value={values.cisloZapisu}
											onChange={handleChange}
											margin="normal"
											variant="standard"
											fullWidth
										/>
									</Grid>

									<Grid item xs={12} sm={6}>
										<StyledTextField
											id="rentgen"
											label="Rentgen"
											value={values.rentgen}
											onChange={handleChange}
											margin="normal"
											variant="standard"
											fullWidth
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<StyledTextField
											id="cisloCipu"
											label="Číslo čipu"
											value={values.cisloCipu}
											onChange={handleChange}
											margin="normal"
											variant="standard"
											fullWidth
										/>
									</Grid>

									<Grid item xs={12} sm={12} md={6}>
										<StyledTextField
											id="zkousky"
											label="Zkoušky"
											value={values.zkousky}
											onChange={handleChange}
											margin="normal"
											variant="standard"
											fullWidth
										/>
									</Grid>

									{isBonitace && (
										<Grid item xs={12} sm={12} md={12}>
											<StyledTextField
												id="oceneni"
												label="Výstavní ocenění"
												value={values.oceneni}
												onChange={handleChange}
												margin="normal"
												variant="standard"
												fullWidth
											/>
										</Grid>
									)}
								</Grid>
							</Grid>

							<Grid container>
								<Grid item xs={12} sm={2}>
									<SectionContainer>
										<Icon
											className="fa fa-venus-mars"
											fontSize="large"
											style={{ width: '1.2em' }}
										/>
										<Typography>Rodiče</Typography>
									</SectionContainer>
								</Grid>
								<Grid item container sm={10} spacing={2}>
									<Grid item xs={12} md={6}>
										<StyledTextField
											id="otecJmeno"
											label="Otec - jméno"
											value={values.otecJmeno}
											onChange={handleChange}
											margin="normal"
											variant="standard"
											fullWidth
										/>
									</Grid>
									{isBonitace && (
										<Grid item xs={12} md={6}>
											<StyledTextField
												id="otecCisloZapisu"
												label="Otec - číslo zápisu"
												value={values.otecCisloZapisu}
												onChange={handleChange}
												margin="normal"
												variant="standard"
												fullWidth
											/>
										</Grid>
									)}
									<Grid item xs={12} md={6}>
										<StyledTextField
											id="matkaJmeno"
											label="Matka - jméno"
											value={values.matkaJmeno}
											onChange={handleChange}
											margin="normal"
											variant="standard"
											fullWidth
										/>
									</Grid>
									{isBonitace && (
										<Grid item xs={12} md={6}>
											<StyledTextField
												id="matkaCisloZapisu"
												label="Matka - číslo zápisu"
												value={values.matkaCisloZapisu}
												onChange={handleChange}
												margin="normal"
												variant="standard"
												fullWidth
											/>
										</Grid>
									)}
								</Grid>
							</Grid>

							<Grid container>
								<Grid item xs={12} sm={2}>
									<SectionContainer>
										<Icon
											className="fa fa-user"
											fontSize="large"
											sx={{ width: '1.2em' }}
										/>
										<Typography>Kontakt</Typography>
									</SectionContainer>
								</Grid>
								<Grid item container sm={10} spacing={2}>
									<Grid item xs={12} md={6}>
										<StyledTextField
											id="chovatelJmeno"
											label="Chovatel - jméno"
											value={values.chovatelJmeno}
											onChange={handleChange}
											margin="normal"
											variant="standard"
											fullWidth
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<StyledTextField
											id="chovatelAdresa"
											label="Chovatel - adresa"
											value={values.chovatelAdresa}
											onChange={handleChange}
											margin="normal"
											variant="standard"
											fullWidth
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<StyledTextField
											id="majitelJmeno"
											label="Majitel - jméno"
											value={values.majitelJmeno}
											onChange={handleChange}
											margin="normal"
											variant="standard"
											fullWidth
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<StyledTextField
											id="majitelAdresa"
											label="Majitel - adresa"
											value={values.majitelAdresa}
											onChange={handleChange}
											margin="normal"
											variant="standard"
											fullWidth
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<StyledTextField
											id="telefon"
											label="Telefon"
											value={values.telefon}
											onChange={handleChange}
											margin="normal"
											variant="standard"
											fullWidth
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<StyledTextField
											id="email"
											label="E-mail"
											value={values.email}
											onChange={handleChange}
											margin="normal"
											variant="standard"
											fullWidth
										/>
									</Grid>
									<Grid item xs={12} md={12}>
										<StyledTextField
											id="zprava"
											label="Zpráva pro pořadatele"
											value={values.zprava}
											onChange={handleChange}
											variant="standard"
											multiline
											fullWidth
										/>
									</Grid>
								</Grid>
							</Grid>
						</Container>

						<Container sx={{ paddingTop: (theme) => theme.spacing() }}>
							<Grid
								container
								spacing={2}
								justifyContent="flex-end"
								alignItems="center"
							>
								<Grid item xs={12} sm={6} md={3}>
									<Button
										type="submit"
										disabled={isSubmitting}
										variant="outlined"
										color="primary"
										startIcon={<SaveIcon />}
										fullWidth
									>
										Zaregistrovat
									</Button>
								</Grid>
							</Grid>
						</Container>
					</form>
				);
			}}
		</Formik>
	);
};

export default NewRegistrationForm;
