import { useState, FC, useEffect } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import { List, arrayMove } from 'react-movable';

import { Save as SaveIcon } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';

import put from '../../libs/put';
import showMessage from '../../utils/showMessage';
import { IDogSort } from '../../types';
import { DogSortExt, getDogCombinations } from './groupCombinations';

const ChangeDogOrder: FC = () => {
	const { mutate } = useSWRConfig();
	const [items, setItems] = useState<DogSortExt[]>([]);

	const { data } = useSWR<IDogSort[]>('/api/settings/dog-sorting', { refreshInterval: 0 });

	useEffect(() => {
		setItems(getDogCombinations(data));
	}, [data]);

	const handleSaveClick = async () => {
		try {
			const reordered = items.map((item, index) => ({
				...item,
				poradi: index + 1,
			}));

			const response = await put(`/api/settings/dog-sorting`, reordered);

			if (response.status === 204) {
				mutate('/api/settings/dog-sorting');
				showMessage({
					msg: `Pořadí uloženo.`,
				});
			}
		} catch (e) {
			console.log(e);
			showMessage({
				msg: `Chyba při ukládání.`,
				severity: 'error',
				duration: 60000,
			});
		}
	};

	return (
		<>
			<Box p={3} pb={4}>
				<Typography>
					Přetažením změníte pořadí, ve kterém se budou řadit psi do katalogu.
				</Typography>
			</Box>
			<Box display="flex" justifyContent="center">
				<List
					lockVertically
					values={items}
					onChange={({ oldIndex, newIndex }) =>
						setItems(arrayMove(items, oldIndex, newIndex))
					}
					renderList={({ children, props, isDragged }) => (
						<Box
							{...props}
							style={{ cursor: isDragged ? 'grabbing' : undefined }}
							p={1}
							bgcolor="grey.200"
							width={310}
							borderRadius="3px"
						>
							{children}
						</Box>
					)}
					renderItem={({ value, props, isDragged, isSelected }) => (
						<Box
							{...props}
							style={{
								...props.style,
								cursor: isDragged ? 'grabbing' : 'grab',
							}}
							p={1}
							m={1}
							boxShadow={isDragged || isSelected ? 2 : 1}
							borderRadius="3px"
							bgcolor={isDragged || isSelected ? 'grey.100' : 'white'}
						>
							{value.label.toLowerCase()}
						</Box>
					)}
				/>
			</Box>
			<Box p={3} display="flex" justifyContent="flex-end">
				<Button
					type="button"
					color="primary"
					startIcon={<SaveIcon />}
					variant="contained"
					onClick={handleSaveClick}
				>
					Uložit
				</Button>
			</Box>
		</>
	);
};

export default ChangeDogOrder;
