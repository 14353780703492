import { FC, useState } from 'react';
import { Formik } from 'formik';
import { useSWRConfig } from 'swr';

import { Save as SaveIcon } from '@mui/icons-material';
import {
	Alert,
	Box,
	Button,
	Checkbox,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	FormLabel,
	Radio,
	RadioGroup,
} from '@mui/material';

import post from '../../libs/post';
import showMessage from '../../utils/showMessage';

interface INewShowData {
	bonitace?: string | null;
}

const NewShow: FC = () => {
	const { mutate } = useSWRConfig();
	const [openDialog, setOpenDialog] = useState(false);

	const [oldSettings, setOldSettings] = useState({
		showSettings: true,
		dogsOrder: true,
		judges: true,
		docxTemplates: true,
		emailTemplates: true,
	});

	const handleCopyOldSettingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setOldSettings({ ...oldSettings, [event.target.name]: event.target.checked });
	};

	const handleSave = async (values: INewShowData) => {
		try {
			const response = await post(`/api/settings/new-show`, { ...values, oldSettings });
			if (response.status === 204) {
				mutate('/api/status');
				mutate('/api/settings/show');
				mutate('/api/settings/dog-sorting');
				mutate('/api/settings/evaluation-sorting');
				mutate('/api/templates/email');
				showMessage({
					msg: `Nová soutěž založena.`,
				});
			}
		} catch (e) {
			console.log(e);
			showMessage({
				msg: `Došlo k chybě při zakládání soutěže.`,
				severity: 'error',
				duration: 60000,
			});
		} finally {
			setOpenDialog(false);
		}
	};

	const handleClickOpenDialog = () => {
		setOpenDialog(true);
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
	};

	return (
		<Formik<INewShowData>
			initialValues={{ bonitace: null }}
			onSubmit={handleClickOpenDialog}
			validate={(values) => {
				const errors: INewShowData = {};

				if (values.bonitace === null) {
					errors.bonitace = 'Typ soutěže musí být zvolen';
				}

				return errors;
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => {
				return (
					<form onSubmit={handleSubmit}>
						<Container maxWidth="md">
							<Box p={3}>
								<Box pb={1}>
									<Alert elevation={0} severity="info">
										Tato stránka se používá pokud chcete vytvořit nový ročník
										soutěže. Přihlášky a výsledky z aktuální soutěže budou
										smazány.
									</Alert>
								</Box>

								<Box p={5}>
									<FormControl
										component="fieldset"
										error={touched && Boolean(errors.bonitace)}
									>
										<FormLabel component="legend">Typ soutěže</FormLabel>
										<RadioGroup
											name="bonitace"
											value={values.bonitace}
											onChange={handleChange}
										>
											<FormControlLabel
												value="0"
												control={<Radio />}
												label="Výstava"
											/>
											<FormControlLabel
												value="1"
												control={<Radio />}
												label="Bonitace"
											/>
										</RadioGroup>
										{touched && Boolean(errors.bonitace) && (
											<FormHelperText>{errors.bonitace}</FormHelperText>
										)}
									</FormControl>
								</Box>
							</Box>

							<Box p={3} display="flex" justifyContent="flex-end">
								<Button
									type="submit"
									color="primary"
									startIcon={<SaveIcon />}
									variant="contained"
								>
									Vytvořit
								</Button>
							</Box>
						</Container>

						<Dialog
							open={openDialog}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
						>
							<DialogTitle id="alert-dialog-title">
								Založit novou {values.bonitace === '1' ? 'bonitaci' : 'výstavu'}?
							</DialogTitle>
							<DialogContent>
								<DialogContent id="alert-dialog-description">
									<Alert severity="warning">
										Všechna data z přihlášek a výstavy budou smazána a založí se
										nová {values.bonitace === '1' ? 'bonitace' : 'výstava'}.
									</Alert>

									<FormControl
										component="fieldset"
										sx={{
											marginTop: (theme) => theme.spacing(3),
										}}
									>
										<FormLabel component="legend">
											Zkopírovat do nového ročníku nastavení z předchozí{' '}
											{values.bonitace === '1' ? 'bonitace' : 'výstavy'}?
										</FormLabel>
										<FormGroup>
											<FormControlLabel
												control={
													<Checkbox
														checked={oldSettings.showSettings}
														onChange={handleCopyOldSettingChange}
														name="showSettings"
													/>
												}
												label={
													values.bonitace === '1'
														? 'Nastavení bonitace'
														: 'Nastavení výstavy'
												}
											/>
											<FormControlLabel
												control={
													<Checkbox
														checked={oldSettings.dogsOrder}
														onChange={handleCopyOldSettingChange}
														name="dogsOrder"
													/>
												}
												label="Řazení psů"
											/>
											<FormControlLabel
												control={
													<Checkbox
														checked={oldSettings.judges}
														onChange={handleCopyOldSettingChange}
														name="judges"
													/>
												}
												label="Rozhodčí"
											/>
											<FormControlLabel
												control={
													<Checkbox
														checked={oldSettings.docxTemplates}
														onChange={handleCopyOldSettingChange}
														name="docxTemplates"
													/>
												}
												label="DOCX šablony"
											/>
											<FormControlLabel
												control={
													<Checkbox
														checked={oldSettings.emailTemplates}
														onChange={handleCopyOldSettingChange}
														name="emailTemplates"
													/>
												}
												label="E-mail šablony"
											/>
										</FormGroup>
									</FormControl>
								</DialogContent>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={() => handleSave(values)}
									color="primary"
									autoFocus
								>
									OK
								</Button>
								<Button onClick={handleCloseDialog} color="secondary">
									Nechci
								</Button>
							</DialogActions>
						</Dialog>
					</form>
				);
			}}
		</Formik>
	);
};

export default NewShow;
