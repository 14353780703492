export const hodnoceni = [
	{ value: '1', label: 'Výborný' },
	{ value: '2', label: 'Velmi dobrý' },
	{ value: '3', label: 'Dobrý' },
	// { value: '4', label: 'Dostatečný' },
	// { value: '5', label: 'Nedostatečný' },
	{ value: '6', label: 'Velmi nadějný' },
	{ value: '7', label: 'Nadějný' },
	{ value: '8', label: 'Nenastoupil' },
	{ value: '9', label: 'Odstoupil' },
];

export const convertHodnoceni = (input?: string): string => {
	const h = hodnoceni.find((item) => item.value === input);
	return h?.label || '?';
};
