import { convertTrida2, convertPohlavi2, convertSrst, convertTypBonitace } from '../../utils';
import { IDogSort, IEvaluationSort } from '../../types';

export interface DogSortExt extends IDogSort {
	label: string;
}

export const getDogCombinations = (input?: IDogSort[]): DogSortExt[] => {
	if (!input) {
		return [];
	}

	const output = input.map((order) => ({
		...order,
		label: `třída ${convertTrida2(order.trida)} ${convertPohlavi2(
			order.pohlavi,
		)} - ${convertSrst(order.srst)} srst`,
	}));

	return output;
};

export interface IEvaluationSortExt extends IEvaluationSort {
	label: string;
}

export const getEvaluationCombinations = (input?: IEvaluationSort[]): IEvaluationSortExt[] => {
	if (!input) {
		return [];
	}

	const output = input.map((order) => ({
		...order,
		label: `${convertPohlavi2(order.pohlavi)} - ${convertTypBonitace(order.typBonitace)}`,
	}));

	return output;
};
